import React from 'react';
import PropTypes from 'prop-types';

import Select from '../../../components/Select/index';
import InputText from '../../../components/InputText/index';
import Tooltip from '../../../components/Tooltip/index';
import Util from '../../../lib/Util';
import RSelect from 'react-select'
import {clientTheme} from '../../../theme';

const client = Util.clientTheme();

class Slide5 extends React.Component {

    constructor(props) {
        super(props);

        const {dataInStore} = this.props;
        this.state = {
            contractualBonus: dataInStore.contractualBonus || '',
            contractualBonusCurrency: dataInStore.contractualBonusCurrency || 'MYR',
            performanceBonus: dataInStore.performanceBonus || '',
            performanceBonusCurrency: dataInStore.performanceBonusCurrency || 'MYR',
            directorsFees: dataInStore.directorsFees || '',
            directorsFeesCurrency: dataInStore.directorsFeesCurrency || 'MYR',
            ASBDividends: dataInStore.ASBDividends || '',
            ASBDividendsCurrency: dataInStore.ASBDividendsCurrency || 'MYR',
            interestDepositsCurrency: dataInStore.interestDepositsCurrency || 'MYR',
            interestDeposits: dataInStore.interestDeposits || '',
            rentalIncomeCurrency: dataInStore.rentalIncomeCurrency || 'MYR',
            rentalIncome: dataInStore.rentalIncome || '',
            overtimeCurrency: dataInStore.overtimeCurrency || 'MYR',
            overtime: dataInStore.overtime || '',
        }

        this.currency = [
            {label: 'AED'},
            {label: 'AUD'},
            {label: 'BND'},
            {label: 'EUR'},
            {label: 'GBP'},
            {label: 'HKD'},
            {label: 'IDR'},
            {label: 'INR'},
            {label: 'JPY'},
            {label: 'MYR'},
            {label: 'NZD'},
            {label: 'QAR'},
            {label: 'RMB'},
            {label: 'SGD'},
            {label: 'THB'},
            {label: 'USD'},
        ];

        this.data = {
            contractualBonusCurrency: 'MYR',
            contractualBonus: 0,
            performanceBonusCurrency: 'MYR',
            performanceBonus: 0,
            directorsFeesCurrency: 'MYR',
            directorsFees: 0,
            ASBDividendsCurrency: 'MYR',
            ASBDividends: 0,
            interestDepositsCurrency: 'MYR',
            interestDeposits: 0,
            rentalIncomeCurrency: 'MYR',
            rentalIncome: 0,
            overtimeCurrency: 'MYR',
            overtime: 0,
        }

        this._changedSelect = this._changedSelect.bind(this);

    }

    _changedSelect(item, value) {
        this.data[item] = value;
        this.props.returnedData(this.data);
        this.data = {}
    }

    onInputChange = (key, value) => {
        this.setState({
            ...this.state,
            [key]: value,
        });
        this.props.onInputChange(key, value);
    }

    render() {
        let {translate} = this.props;
        const sizeInfo = client === "uems" ? {label: 4, input: 8} : {label: 5, input: 7};
        return (

            <div className="slideMinH">
                {/* <div className="topTitleLine" /> */}
                <h2>
                    {
                        this.props.coApplicantStatus ?
                            translate("slide5.title.co")
                            :
                            translate("slide5.title.main")
                    }
                </h2>

                <h3 id={"TooltipYearlyAverage1"}>
                    {translate("slide5.subtitle.yearly")}
                    <Tooltip
                        parent="TooltipYearlyAverage"
                        text={translate("slide5.tooltip.yearly")}
                    />
                </h3>

                <div className="row selectInputBox">
                    <div className="col-sm-6">
                        <div className="SelectTopTitle">
                            <span>{translate("slide5.placeHolder.contractual")}</span>
                        </div>
                        <div className="row remove-mb">
                            <div className={`col-sm-${sizeInfo.label} col-xs-5`}>
                                <Select
                                    data={this.currency}
                                    initial={9}
                                    id='contractualBonusCurrency'
                                    value={this.state.contractualBonusCurrency}
                                    onChange={(key, value) => this.onInputChange(key, value)}
                                />
                            </div>
                            <div className={`col-sm-${sizeInfo.input} col-xs-7`}>
                                <InputText
                                    id='contractualBonus'
                                    value={this.state.contractualBonus}
                                    onChange={(key, value) => this.onInputChange(key, value)}
                                    placeholder={translate("slide5.placeHolder.contractual")}
                                    autoFocus={true}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="SelectTopTitle">
                            <span>{translate("slide5.placeHolder.performance")}</span>
                        </div>
                        <div className="row">
                            <div className={`col-sm-${sizeInfo.label} col-xs-5`}>
                                <Select
                                    data={this.currency}
                                    initial={9}
                                    id='performanceBonusCurrency'
                                    value={this.state.performanceBonusCurrency}
                                    onChange={(key, value) => this.onInputChange(key, value)}
                                />
                            </div>
                            <div className={`col-sm-${sizeInfo.input} col-xs-7`}>
                                <InputText
                                    id='performanceBonus'
                                    value={this.state.performanceBonus}
                                    onChange={(key, value) => this.onInputChange(key, value)}
                                    placeholder={translate("slide5.placeHolder.performance")}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row selectInputBox">
                    <div className="col-sm-6">
                        <div className="SelectTopTitle">
                            <span>{translate("slide5.placeHolder.directors")}</span>
                        </div>
                        <div className="row">
                            <div className={`col-sm-${sizeInfo.label} col-xs-5`}>
                                <Select
                                    data={this.currency}
                                    initial={9}
                                    id='directorsFeesCurrency'
                                    value={this.state.directorsFeesCurrency}
                                    onChange={(key, value) => this.onInputChange(key, value)}
                                />
                            </div>
                            <div className={`col-sm-${sizeInfo.input} col-xs-7`}>
                                <InputText
                                    id='directorsFees'
                                    value={this.state.directorsFees}
                                    onChange={(key, value) => this.onInputChange(key, value)}
                                    placeholder={translate("slide5.placeHolder.directors")}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="SelectTopTitle">
                            <span>{translate("slide5.placeHolder.ASB")}</span>
                        </div>
                        <div className="row">
                            <div className={`col-sm-${sizeInfo.label} col-xs-5`}>
                                <Select
                                    data={this.currency}
                                    initial={9}
                                    id='ASBDividendsCurrency'
                                    value={this.state.ASBDividendsCurrency}
                                    onChange={(key, value) => this.onInputChange(key, value)}
                                />
                            </div>
                            <div className={`col-sm-${sizeInfo.input} col-xs-7`}>
                                <InputText
                                    id='ASBDividends'
                                    value={this.state.ASBDividends}
                                    onChange={(key, value) => this.onInputChange(key, value)}
                                    placeholder={translate("slide5.placeHolder.ASB")}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row selectInputBox">
                    <div className="col-sm-6">
                        <div className="SelectTopTitle">
                            <span>{translate("slide5.placeHolder.interest")}</span>
                        </div>
                        <div className="row">
                            <div className={`col-sm-${sizeInfo.label} col-xs-5`}>
                                <Select
                                    data={this.currency}
                                    initial={9}
                                    id='interestDepositsCurrency'
                                    value={this.state.interestDepositsCurrency}
                                    onChange={(key, value) => this.onInputChange(key, value)}
                                />
                            </div>
                            <div className={`col-sm-${sizeInfo.input} col-xs-7`}>
                                <InputText
                                    id='interestDeposits'
                                    value={this.state.interestDeposits}
                                    onChange={(key, value) => this.onInputChange(key, value)}
                                    placeholder={translate("slide5.placeHolder.interest")}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">

                    </div>
                </div>

                <h3 id={"TooltipMonthlyAverage1"}>
                    {translate("slide5.subtitle.monthly")}
                    <Tooltip
                        parent="TooltipMonthlyAverage"
                        text={translate("slide5.tooltip.monthly")}
                    />
                </h3>
                <div className="row selectInputBox">
                    <div className="col-sm-6">
                        <div className="SelectTopTitle">
                            <span>{translate("slide5.placeHolder.rental")}</span>
                        </div>
                        <div className="row">
                            <div className={`col-sm-${sizeInfo.label} col-xs-5`}>
                                <Select
                                    data={this.currency}
                                    initial={9}
                                    id='rentalIncomeCurrency'
                                    value={this.state.rentalIncomeCurrency}
                                    onChange={(key, value) => this.onInputChange(key, value)}
                                />
                            </div>
                            <div className={`col-sm-${sizeInfo.input} col-xs-7`}>
                                <InputText
                                    id='rentalIncome'
                                    value={this.state.rentalIncome}
                                    onChange={(key, value) => this.onInputChange(key, value)}
                                    placeholder={translate("slide5.placeHolder.rental")}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="SelectTopTitle">
                            <span>{translate("slide5.placeHolder.overtime")}</span>
                        </div>
                        <div className="row">
                            <div className={`col-sm-${sizeInfo.label} col-xs-5`}>
                                <Select
                                    data={this.currency}
                                    initial={9}
                                    id='overtimeCurrency'
                                    value={this.state.overtimeCurrency}
                                    onChange={(key, value) => this.onInputChange(key, value)}
                                />
                            </div>
                            <div className={`col-sm-${sizeInfo.input} col-xs-7`}>
                                <InputText
                                    id='overtime'
                                    value={this.state.overtime}
                                    onChange={(key, value) => this.onInputChange(key, value)}
                                    placeholder={translate("slide5.placeHolder.overtime")}
                                />
                            </div>
                        </div>
                    </div>
                </div>


            </div>

        )
    }
}

/* Export Component =============================== */
export default Slide5;