import React from 'react';
import PropTypes from 'prop-types';
import Util from '../../lib/Util';

import './style.scss';
import { InputTextItem, InputUp, InputDown } from "./styled";

class InputText extends React.Component{
    
    constructor(props) {
        super(props);

        this.state = {
        }


        this._onChange = this._onChange.bind(this);
        this._inputUp = this._inputUp.bind(this);
        this._inputDown = this._inputDown.bind(this);

    }
    _inputUp(key, value) {
        if (value > 0) {
            value = Number(value) + 1;
        }
        else {
            value = 1;
        }
        this.props.onChange(key, value);
    }

    _inputDown(key, value) {
        if(value > 0) {  
            value = Number(value) - 1;
        }
        else {
            value = 0;
        }
        this.props.onChange(key, value);

    }

    _onChange(e) {
        let { text } = this.props;

        const key = e && e.target && e.target.id;
        let value = e && e.target && e.target.value;
        if (!text) {
            value = value.replace(/\D/g,'');;
        }
        if(key === "phoneNumber") {
            value = Util.removeChar(value);
        }
        this.props.onChange(key, value);
      }
    render(){

        let { placeholder, text, style, maxlength, autoFocus } = this.props;
        const { value } = this.state;
        return(
            text ?
                <InputTextItem
                    id={this.props.id}
                    value={this.props.value}
                    onChange={(e) => this._onChange(e)}
                    className="inputText"
                    placeholder={placeholder}
                    style={style}
                    maxLength={maxlength || 99999}
                    autoFocus={autoFocus}
                />
            :
                <div className="number-wrapper">
                    <InputTextItem
                        id = {this.props.id}
                        // type="number"
                        value={this.props.value}
                        onChange={(e) => this._onChange(e)}
                        className="inputText"
                        placeholder={placeholder}
                        min="0"
                        autoFocus={autoFocus}
                    />
                    <div className="inputArrowBtn">
                        <InputUp className={`fa fa-chevron-up inputUp ${this.props.id+'arrow'}`} onClick={() => this._inputUp(this.props.id,this.props.value )}/>
                        <InputDown className={`fa fa-chevron-down inputDown ${this.props.id+'arrow'}`} onClick={() => this._inputDown(this.props.id,this.props.value)}/>
                    </div>
                </div>

        )
     }
}


InputText.propTypes = {
    placeholder: PropTypes.string,
    phoneNumber: PropTypes.bool,
    text: PropTypes.bool
};

InputText.defaultProps = {
    placeholder: '',
    phoneNumber: false,
    text: false
};

/* Export Component =============================== */
export default InputText;