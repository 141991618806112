import React from 'react';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Util from '../../../lib/Util';
import {clientTheme} from '../../../theme';

const client = Util.clientTheme();

class Slide7 extends React.Component {

    constructor(props) {
        super(props);
        this.yesRadioRef = React.createRef();
        this.state = {
            value: this.props.editData['co-applicant'] || ''
        }

        this.radio = [
            {
                id: 0,
                label: 'Yes'
            },
            {
                id: 1,
                label: 'No'
            }
        ];

        this.data = {}

        this.handleChange = this.handleChange.bind(this);
        this._changedSelect = this._changedSelect.bind(this);
        this._renderData = this._renderData.bind(this);
        this._editInfo = this._editInfo.bind(this);
    }

    componentDidMount() {
        this.focusYesRadio();
    }

    static getDerivedStateFromProps(props, state) {
        if (props.editStatus) {
            return {
                value: props.editData['co-applicant']
            };
        }
    }

    focusYesRadio = () => {
        this.yesRadioRef.current.focus();
    }

    handleChange(event) {
        this.setState({value: event.target.value})
        this.data['co-applicant'] = event.target.value;
        this.props.returnedData(this.data);
    };

    handleKeyDown = (id, e) => {
        if (e.key === 'Enter') {
            this.handleChange({target: {value: this.radio[id].label}});
        }
    }

    _changedSelect(item, value) {
        this.data[item] = value;
        this.props.returnedData(this.data);
    }

    _editInfo(data, title, type) {
        this.props.editInfoClicked(data, title, type)
    }

    _renderData(data, title, type, styleName) {
        let {translate} = this.props;
        return (
            <>
                <div className={"summaryBox sammarySubtitle sammaryHeaderTitle row " + styleName}>

                    <div className="col-sm-6 col-xs-6 sammarySubtitleLeft">{title}</div>
                    <div className="col-sm-6 col-xs-6 sammarySubtitleRight">
                        <div
                            className={"edit-uems-btn"}
                            onClick={() => this._editInfo(data, title, type)}>{translate("slide9.placeHolder.edit")}</div>
                    </div>
                </div>
                <div className="summaryBox">
                    <div className="sammarySubtitle">
                        {translate("slide9.placeHolder.basic")}
                    </div>
                    <div className="row summaryItem">
                        <div className="col-sm-6 col-xs-6">{translate("slide8.placeHolder.name")}</div>
                        <div className="col-sm-6 col-xs-6">{data.name}</div>
                    </div>
                    <div className="row summaryItem">
                        <div className="col-sm-6 col-xs-6">{translate("slide8.placeHolder.age")}</div>
                        <div className="col-sm-6 col-xs-6">{data.age}</div>
                    </div>
                    <div className="row summaryItem">
                        <div className="col-sm-6 col-xs-6">{translate("slide8.placeHolder.gender.gender")}</div>
                        <div className="col-sm-6 col-xs-6">{data.gender}</div>
                    </div>
                    <div className="row summaryItem">
                        <div className="col-sm-6 col-xs-6">{translate("slide9.placeHolder.email")}</div>
                        <div className="col-sm-6 col-xs-6">{data.email}</div>
                    </div>
                    <div className="row summaryItem">
                        <div className="col-sm-6 col-xs-6">{translate("slide8.placeHolder.phone")}</div>
                        <div className="col-sm-6 col-xs-6">+{data.mobileNumber}</div>
                    </div>
                </div>

                {
                    (data.basicSalary ||
                        data.fixedAllowance ||
                        data.commission ||
                        data.totalIncome) &&
                    <div className="summaryBox summaryBoxWithBorder">
                        <div className="sammarySubtitle">
                            {translate("slide9.placeHolder.main")}
                        </div>
                        {
                            (data.basicSalary && data.userType === 'Salaried' && data.incomeType !== 'Pure Commission') ?
                                <div className="row summaryItem">
                                    <div className="col-sm-6 col-xs-6">{translate("slide4.placeHolder.basic")}</div>
                                    <div
                                        className="col-sm-6 col-xs-6">{data.basicSalaryCurrency || 'MYR'} {Util.convertNum(data.basicSalary)}</div>
                                </div>
                                :
                                null
                        }
                        {
                            (data.fixedAllowance && data.userType === 'Salaried' && data.incomeType !== 'Pure Commission') ?
                                <div className="row summaryItem">
                                    <div className="col-sm-6 col-xs-6">{translate("slide4.placeHolder.fixed")}</div>
                                    <div
                                        className="col-sm-6 col-xs-6">{data.fixedAllowanceCurrency || 'MYR'} {Util.convertNum(data.fixedAllowance)}</div>
                                </div>
                                :
                                null
                        }
                        {
                            (data.commission && data.userType === 'Salaried' && data.incomeType !== 'Basic Salary') ?
                                <div className="row summaryItem">
                                    <div
                                        className="col-sm-6 col-xs-6">{translate("slide4.placeHolder.commission")}</div>
                                    <div
                                        className="col-sm-6 col-xs-6">{data.commissionCurrency || 'MYR'} {Util.convertNum(data.commission)}</div>
                                </div>
                                :
                                null
                        }
                        {
                            (data.totalIncome && data.userType === 'Self') ?
                                <div className="row summaryItem">
                                    <div className="col-sm-6 col-xs-6">{translate("slide9.placeHolder.bForm")}</div>
                                    <div
                                        className="col-sm-6 col-xs-6">{data.totalIncomeCurrency || 'MYR'} {Util.convertNum(data.totalIncome)}</div>
                                </div>
                                :
                                null
                        }
                    </div>
                }

                {
                    (data.contractualBonus ||
                        data.performanceBonus ||
                        data.directorsFees ||
                        data.ASBDividends ||
                        data.interestDeposits ||
                        data.rentalIncome ||
                        data.overtime) &&
                    <div className="summaryBox summaryBoxWithBorder">
                        <div className="sammarySubtitle">
                            {translate("slide9.placeHolder.additional")}
                        </div>
                        {
                            data.contractualBonus ?
                                <div className="row summaryItem">
                                    <div
                                        className="col-sm-6 col-xs-6">{translate("slide5.placeHolder.contractual")}</div>
                                    <div
                                        className="col-sm-6 col-xs-6">{data.contractualBonusCurrency || 'MYR'} {Util.convertNum(data.contractualBonus)}</div>
                                </div>
                                :
                                null
                        }
                        {
                            data.performanceBonus ?
                                <div className="row summaryItem">
                                    <div
                                        className="col-sm-6 col-xs-6">{translate("slide5.placeHolder.performance")}</div>
                                    <div
                                        className="col-sm-6 col-xs-6">{data.performanceBonusCurrency || 'MYR'} {Util.convertNum(data.performanceBonus)}</div>
                                </div>
                                :
                                null
                        }
                        {
                            data.directorsFees ?
                                <div className="row summaryItem">
                                    <div className="col-sm-6 col-xs-6">{translate("slide5.placeHolder.directors")}</div>
                                    <div
                                        className="col-sm-6 col-xs-6">{data.directorsFeesCurrency || 'MYR'} {Util.convertNum(data.directorsFees)}</div>
                                </div>
                                :
                                null
                        }
                        {
                            data.ASBDividends ?
                                <div className="row summaryItem">
                                    <div className="col-sm-6 col-xs-6">{translate("slide5.placeHolder.ASB")}</div>
                                    <div
                                        className="col-sm-6 col-xs-6">{data.ASBDividendsCurrency || 'MYR'} {Util.convertNum(data.ASBDividends)}</div>
                                </div>
                                :
                                null
                        }
                        {
                            data.interestDeposits ?
                                <div className="row summaryItem">
                                    <div className="col-sm-6 col-xs-6">{translate("slide5.placeHolder.interest")}</div>
                                    <div
                                        className="col-sm-6 col-xs-6">{data.interestDepositsCurrency || 'MYR'} {Util.convertNum(data.interestDeposits)}</div>
                                </div>
                                :
                                null
                        }
                        {
                            data.rentalIncome ?
                                <div className="row summaryItem">
                                    <div className="col-sm-6 col-xs-6">{translate("slide5.placeHolder.rental")}</div>
                                    <div
                                        className="col-sm-6 col-xs-6">{data.rentalIncomeCurrency || 'MYR'} {Util.convertNum(data.rentalIncome)}</div>
                                </div>
                                :
                                null
                        }
                        {
                            data.overtime ?
                                <div className="row summaryItem">
                                    <div className="col-sm-6 col-xs-6">{translate("slide5.placeHolder.overtime")}</div>
                                    <div
                                        className="col-sm-6 col-xs-6">{data.overtimeCurrency || 'MYR'} {Util.convertNum(data.overtime)}</div>
                                </div>
                                :
                                null
                        }
                    </div>
                }

                {
                    data.incomeTax &&
                    <div className="summaryBox summaryBoxWithBorder">
                        <div className="sammarySubtitle">
                            {translate("slide9.placeHolder.monthlyTax")}
                        </div>
                        {
                            data.incomeTax ?
                                <div className="row summaryItem">
                                    <div className="col-sm-6 col-xs-6">{translate("slide9.placeHolder.PCB")}</div>
                                    <div
                                        className="col-sm-6 col-xs-6">{data.incomeTaxCurrency || 'MYR'} {Util.convertNum(data.incomeTax)}</div>
                                </div>
                                :
                                null
                        }
                    </div>
                }

                {
                    (data.homeLoan ||
                        data.carLoan ||
                        data.personalLoan ||
                        data.PTPTN ||
                        data.otherLoans ||
                        data.creditCard ||
                        data.overdraft) &&
                    <div className="summaryBox summaryBoxWithBorder">
                        <div className="sammarySubtitle">
                            {translate("slide9.placeHolder.monthlyCom")}
                        </div>
                        {
                            data.homeLoan ?
                                <div className="row summaryItem">
                                    <div className="col-sm-6 col-xs-6">{translate("slide7.placeHolder.home")}</div>
                                    <div
                                        className="col-sm-6 col-xs-6">{data.homeLoanCurrency || 'MYR'} {Util.convertNum(data.homeLoan)}</div>
                                </div>
                                :
                                null
                        }
                        {
                            data.carLoan ?
                                <div className="row summaryItem">
                                    <div className="col-sm-6 col-xs-6">{translate("slide7.placeHolder.car")}</div>
                                    <div
                                        className="col-sm-6 col-xs-6">{data.carLoanCurrency || 'MYR'} {Util.convertNum(data.carLoan)}</div>
                                </div>
                                :
                                null
                        }
                        {
                            data.personalLoan ?
                                <div className="row summaryItem">
                                    <div className="col-sm-6 col-xs-6">{translate("slide7.placeHolder.personal")}</div>
                                    <div
                                        className="col-sm-6 col-xs-6">{data.personalLoanCurrency || 'MYR'} {Util.convertNum(data.personalLoan)}</div>
                                </div>
                                :
                                null
                        }
                        {
                            data.PTPTN ?
                                <div className="row summaryItem">
                                    <div className="col-sm-6 col-xs-6">{translate("slide7.placeHolder.PTPTN")}</div>
                                    <div
                                        className="col-sm-6 col-xs-6">{data.PTPTNCurrency || 'MYR'} {Util.convertNum(data.PTPTN)}</div>
                                </div>
                                :
                                null
                        }
                        {
                            data.otherLoans ?
                                <div className="row summaryItem">
                                    <div className="col-sm-6 col-xs-6">{translate("slide7.placeHolder.other")}</div>
                                    <div
                                        className="col-sm-6 col-xs-6">{data.otherLoansCurrency || 'MYR'} {Util.convertNum(data.otherLoans)}</div>
                                </div>
                                :
                                null
                        }
                        {
                            data.creditCard ?
                                <div className="row summaryItem">
                                    <div
                                        className="col-sm-6 col-xs-6">{translate("slide7.placeHolder.creditCard")}</div>
                                    <div
                                        className="col-sm-6 col-xs-6">{data.creditCardCurrency || 'MYR'} {Util.convertNum(data.creditCard)}</div>
                                </div>
                                :
                                null
                        }
                        {
                            data.overdraft ?
                                <div className="row summaryItem">
                                    <div className="col-sm-6 col-xs-6">{translate("slide7.placeHolder.overdraft")}</div>
                                    <div
                                        className="col-sm-6 col-xs-6">{data.overdraftCurrency || 'MYR'} {Util.convertNum(data.overdraft)}</div>
                                </div>
                                :
                                null
                        }
                    </div>
                }
            </>
        )
    }

    render() {
        let {data, coApplicant, translate} = this.props;
        let coCount = 0;
        return (

            <div>
                {/* <div className="topTitleLine" /> */}
                <h2 className="summaryTitle">
                    {translate("slide9.title.main")}
                </h2>

                <div className="summary-wrap clearfix">
                    <div className="summary-scroll">

                        {this._renderData(data, translate("slide9.placeHolder.mainApplicant"), 'mainApplicant', '')}

                        {
                            coApplicant.map((item) => {
                                if (item.email) {
                                    coCount++;
                                    return (
                                        this._renderData(item, `${translate("slide9.placeHolder.coApplicant")} ${coCount}`, 'coApplicant', 'sammaryHeaderTopBorder')
                                    )
                                }
                            })
                        }

                    </div>
                </div>

                <div className="selectInputBox">
                    <div>
                        <h3>
                            {translate("slide9.subtitle.addCo")}
                        </h3>
                        <div style={{margin: 15}}>
                            <RadioGroup value={this.state.value} aria-label="position" row onChange={this.handleChange}>
                                <FormControlLabel
                                    ref={this.yesRadioRef}
                                    tabIndex={-1}
                                    onKeyDown={(e) => this.handleKeyDown(this.radio[0].id, e)}
                                    value={this.radio[0].label} control={<Radio color="default"/>}
                                    label={translate("slide9.placeHolder.yes")} labelPlacement="end"/>
                                <FormControlLabel
                                    tabIndex={-1}
                                    onKeyDown={(e) => this.handleKeyDown(this.radio[1].id, e)}
                                    value={this.radio[1].label} control={<Radio color="default"/>}
                                    label={translate("slide9.placeHolder.no")} labelPlacement="end"/>
                            </RadioGroup>
                        </div>
                    </div>
                </div>

                <div className="NoRadioButton">
                    {
                        this.state.value === 'No' &&
                        <p style={{
                            ...(client === "uems" ? {color: "#E8112D"} : {})
                        }}>
                            {translate("slide9.subtitle.agree1")}
                            {clientTheme[client].name}
                            {translate(client === "uems" ? "slide9.subtitle.agree2-uems" : "slide9.subtitle.agree2")}
                        </p>
                    }
                </div>


            </div>

        )
    }
}


/* Export Component =============================== */
export default Slide7;