import React from 'react';
import PropTypes from 'prop-types';
import Slider from "react-slick";
import ReactTooltip from 'react-tooltip';
import {ToastContainer, toast} from 'react-toastify';
import {withTranslation} from 'react-i18next';

import './style.scss';
import {Form, CarouselEligibilityCheck, PoweredBy, EligibilityControls} from "./styled";

import {clientTheme} from '../../theme';

import Util from '../../lib/Util';
import {Loader} from '../../components/loader';
import Header from '../../components/Header/index';
import Slide1 from './slides/slide1';
import Slide2 from './slides/slide2';
import Slide3 from './slides/slide3';
import Slide4 from './slides/slide4';
import Slide5 from './slides/slide5';
import Slide6 from './slides/slide6';
import Slide7 from './slides/slide7';
import Slide8 from './slides/slide8';
import Slide9 from './slides/slide9';
import Slide10 from './slides/slide10';

const initialState = {
    circularProgressValue: 0,
    slideIndex: 0,
    userType: '',
    incomeType: '',
    income: {},
    additionalIncome: {},
    incomeTax: {},
    commitments: {},
    basicDetails: {},
    summary: {},
    emailDetails: {},
    eligibilityCheckData: {},
    coApplicantStatus: false,
    removeDetailStatus: 1,
    coApplicantId: -1,
    validateEmail: true,
    waitForResStatus: false,
    editData: {},
    editTitle: '',
    editType: '',
    editStatus: false,
    dataCode: ''
};

class Home extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            focusOn: false,
            ...initialState,
            ...this.props.loanData,
            coApplicant: this.props.coApplicant,
            languageShow: 'EN'
        };

        this.toastConfig = {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
        }

        this._next = this._next.bind(this);
        this._previous = this._previous.bind(this);
        this._saveData = this._saveData.bind(this);
        this._editInfoClicked = this._editInfoClicked.bind(this);
        this.showMenu = this.showMenu.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
    }

    async componentDidMount() {
        const ln = Util.getQueryString(window.location.href, 'language');
        if (ln && ln.toLowerCase() === 'cn') {
            this.changeLanguage('cn');
        } else {
            this.changeLanguage('en');
        }

        const data = Util.getDataFromUrl(window.location);

        this.setState({
            dataCode: Util.clientTheme()
        })
        await this.props.isApiKeyValid(data.hostname, data.code, data.token);

        this.props.getIPAction();

        setTimeout(() => {
            if ((Util.clientTheme() === 'tcb' || Util.clientTheme() === 'default') && !this.props.auth.debugger) {
                const script = document.createElement("script");
                script.src = clientTheme[Util.clientTheme()].scriptUrl;
                // script.src = "/tcb-script.js";
                script.type = 'text/javascript';
                script.async = true;
                document.body.appendChild(script);
            }
        }, 300);
    }

    _next() {
        let {incomeType, userType, income, basicDetails} = this.state;

        let sliderIndex = this.state.slideIndex;

        if (this.state.userType.userType === 'Self' && sliderIndex === 0) {
            sliderIndex = sliderIndex + 1;
        }

        if (sliderIndex === 1 && !userType) {
            toast.error(this.props.t('error.employment'), this.toastConfig);
            return;
        } else if (sliderIndex === 1 && userType === -1) {
            toast.error(this.props.t('error.employment'), this.toastConfig);
            return;
        } else if (sliderIndex === 2 && !incomeType) {
            toast.error(this.props.t('error.income'), this.toastConfig);
            return;
        } else if (sliderIndex === 3) {
            if (userType.userType === 'Salaried' && incomeType.incomeType === 'Basic Salary' && !income.basicSalary) {
                toast.error(this.props.t('error.basicSalaryEmpty'), this.toastConfig);
                return;
            } else if (userType.userType === 'Salaried' &&
                incomeType.incomeType === 'Basic Salary + Variable Income / Commission' &&
                !income.basicSalary) {
                toast.error(this.props.t('error.basicSalaryEmpty'), this.toastConfig);
                return;
            } else if (userType.userType === 'Salaried' &&
                incomeType.incomeType === 'Basic Salary + Variable Income / Commission' &&
                !income.commission) {
                toast.error(this.props.t('error.commissionEmpty'), this.toastConfig);
                return;
            } else if (userType.userType === 'Salaried' &&
                incomeType.incomeType === 'Pure Commission' &&
                !income.commission) {
                toast.error(this.props.t('error.commissionEmpty'), this.toastConfig);
                return;
            } else if (userType.userType === 'Self' && !income.totalIncome) {
                toast.error(this.props.t('error.declarationEmpty'), this.toastConfig);
                return;
            }
        } else if (sliderIndex === 7) {
            if (!basicDetails.name) {
                toast.error(this.props.t('error.nameEmpty'), this.toastConfig);
                return;
            } else if (!basicDetails.age || basicDetails.age === 0 || basicDetails.age === this.props.t('slide8.placeHolder.selectAge')) {
                toast.error(this.props.t('error.ageEmpty'), this.toastConfig);
                return;
            } else if (!basicDetails.gender) {
                toast.error(this.props.t('error.genderEmpty'), this.toastConfig);
                return;
            } else if (!basicDetails.email) {
                toast.error(this.props.t('error.emailEmpty'), this.toastConfig);
                return;
            } else if (!Util.validateEmail(basicDetails.email)) {
                toast.error(this.props.t('error.emailValidate'), this.toastConfig);
                return;
            } else if (!basicDetails.mobileNumber) {
                toast.error(this.props.t('error.mobileEmpty'), this.toastConfig);
                return;
            } else if (!Util.validateMobile(basicDetails.mobileNumber)) {
                toast.error(this.props.t('error.mobileValidate'), this.toastConfig);
                return;
            }
        } else if (sliderIndex === 8) {
            if (!this.state.summary['co-applicant'] || this.state.summary['co-applicant'] === -1) {
                toast.error(this.props.t('error.coEmpty'), this.toastConfig);
                return;
            } else if (this.state.summary['co-applicant'] === 'Yes') {
                this._saveData();
                const coApplicantId = Util.randomNumber(100000, 999999);
                this.props.addNewApplicant(coApplicantId);
                this._editInfoClicked({}, 'title', 'type', 1)
                const userTypeData = {
                    userType: '-1',
                    mobileNumber: "",
                    gender: '',
                    slide9Radio: -1,
                    'co-applicant': -1
                }
                this.setState({
                    editData: userTypeData,
                    userType: -1,
                    incomeType: '',
                    editStatus: true,
                    basicDetails: {
                        name: "",
                        age: "",
                        gender: "",
                        email: "",
                        mobileNumber: ""
                    },

                    coApplicantStatus: true,
                    removeDetailStatus: 2,
                    coApplicantId
                });

                this.setState({
                    slideIndex: 1,
                    summary: {'co-applicant': -1},
                })
                return;
            } else {
                this.setState({waitForResStatus: true});
                this.props.eligibilityCheckAction()
                    .then((res) => {
                        if (res.code === 200) {
                            this._saveData();
                            this.setState({eligibilityCheckData: res.results})
                            this.setState({waitForResStatus: false, slideIndex: this.state.slideIndex + 1});
                        } else if (res.verified === false) {
                            this.props.unauthorizedRequest();
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        this.setState({waitForResStatus: false});
                    })
            }
            this.setState({
                summary: {'co-applicant': -1}
            })
            return;
        } else if (sliderIndex === 9) {
            this._saveData();
            this.setState({...this.state, slideIndex: 1});
            return;
        } else if (sliderIndex === 10) {
            this.props.eligibilityEmailAction(this.state.eligibilityCheckData.id, this.state.emailDetails)
                .then((res) => {
                    console.log(res)
                })
                .catch((err) => {
                    console.log(err);
                })
            return;
        }

        this._saveData();

        if (sliderIndex === 1 && userType.userType === 'Self') {
            this.setState({...this.state, slideIndex: 3});
            return;
        } else if (sliderIndex === 4 && userType.userType === 'Self') {
            this.setState({...this.state, slideIndex: 6});
            return;
        }
        this.setState({...this.state, slideIndex: sliderIndex + 1});

    }

    _previous() {
        let sliderIndex = this.state.slideIndex;

        if (this.state.editStatus && this.state.slideIndex === 1) {
            this.setState({...this.state, editStatus: false, slideIndex: 8});
            return;
        } else if (this.state.coApplicantStatus && this.state.slideIndex === 1) {
            this.setState({...this.state, coApplicantStatus: false, slideIndex: 8});
            return;
        }
        if (sliderIndex === 3 && this.state.userType.userType === 'Self') {
            this.setState({...this.state, slideIndex: 1});
            return;
        } else if (sliderIndex === 6 && this.state.userType.userType === 'Self') {
            this.setState({...this.state, slideIndex: 4});
            return;
        }

        if (this.state.slideIndex === 1) {
            /** pre is let's begin, initiate the state */
            this.setState({...initialState})
        } else {
            this.setState({...this.state, slideIndex: this.state.slideIndex - 1});
        }


    }

    _saveData() {

        let {coApplicantStatus, coApplicantId} = this.state;
        let sliderIndex = this.state.slideIndex;

        if (sliderIndex === 1) {
            if (coApplicantStatus) {
                this.props.updateCoApplicantData(coApplicantId, this.state.userType);
                this.props.updateCoApplicantData(coApplicantId, this.state.income);
                return;
            }
            this.props.updateUserData(this.state.userType);
            this.props.updateUserData(this.state.income);
        } else if (sliderIndex === 2) {
            if (coApplicantStatus) {
                this.props.updateCoApplicantData(coApplicantId, this.state.incomeType);
                this.props.updateCoApplicantData(coApplicantId, this.state.income);
                return;
            }
            this.props.updateUserData(this.state.incomeType);
            this.props.updateUserData(this.state.income);
        } else if (sliderIndex === 3) {
            if (coApplicantStatus) {
                this.props.updateCoApplicantData(coApplicantId, this.state.income);
                return;
            }
            this.props.updateUserData(this.state.income);
        } else if (sliderIndex === 4) {
            if (coApplicantStatus) {
                this.props.updateCoApplicantData(coApplicantId, this.state.additionalIncome);
                return;
            }

            this.props.updateUserData(this.state.additionalIncome);
        } else if (sliderIndex === 5) {
            if (coApplicantStatus) {
                this.props.updateCoApplicantData(coApplicantId, this.state.incomeTax);
                return;
            }
            this.props.updateUserData(this.state.incomeTax);
        } else if (sliderIndex === 6) {
            if (coApplicantStatus) {
                this.props.updateCoApplicantData(coApplicantId, this.state.commitments);
                return;
            }
            this.props.updateUserData(this.state.commitments);
        } else if (sliderIndex === 7) {
            if (coApplicantStatus) {
                this.props.updateCoApplicantData(coApplicantId, this.state.basicDetails);
                return;
            }
            this.props.updateUserData(this.state.basicDetails);
        }
    }

    _editInfoClicked(data, title, type, slideIndex) {

        const userTypeData = {
            userType: data.userType
        }
        this.setState({editData: data, editTitle: title, editType: type, userType: userTypeData, editStatus: true});
        if (type === 'mainApplicant') {
            this.setState({coApplicantStatus: false, coApplicantId: 0});
        } else {
            this.setState({coApplicantStatus: true, coApplicantId: data.id});
        }
        const incomeTypeData = {
            incomeType: data.incomeType
        }
        const incomeData = {
            totalIncome: data.totalIncome || '',
            totalIncomeCurrency: data.totalIncomeCurrency || '',
            basicSalary: data.basicSalary || '',
            basicSalaryCurrency: data.basicSalaryCurrency || '',
            fixedAllowance: data.fixedAllowance || '',
            fixedAllowanceCurrency: data.fixedAllowanceCurrency || '',
            commission: data.commission || '',
            commissionCurrency: data.commissionCurrency || ''
        }
        const additionalIncomeData = {
            contractualBonusCurrency: data.contractualBonusCurrency || 'MYR',
            contractualBonus: data.contractualBonus || '',
            performanceBonusCurrency: data.performanceBonusCurrency || 'MYR',
            performanceBonus: data.performanceBonus || '',
            directorsFeesCurrency: data.directorsFeesCurrency || 'MYR',
            directorsFees: data.directorsFees || '',
            ASBDividendsCurrency: data.ASBDividendsCurrency || 'MYR',
            ASBDividends: data.ASBDividends || '',
            interestDepositsCurrency: data.interestDepositsCurrency || 'MYR',
            interestDeposits: data.interestDeposits || '',
            rentalIncomeCurrency: data.rentalIncomeCurrency || 'MYR',
            rentalIncome: data.rentalIncome || '',
            overtimeCurrency: data.overtimeCurrency || 'MYR',
            overtime: data.overtime || '',
        }
        const incomeTaxData = {
            incomeTax: data.incomeTax || ''
        }
        const commitmentsData = {
            homeLoan: data.homeLoan || '',
            carLoan: data.carLoan || '',
            personalLoan: data.personalLoan || '',
            PTPTN: data.PTPTN || '',
            otherLoans: data.otherLoans || '',
            creditCard: data.creditCard || '',
            overdraft: data.overdraft || ''
        }
        const basicDetailsData = {
            name: data.name || '',
            age: data.age || 0,
            gender: data.gender || '',
            email: data.email || '',
            mobileNumber: data.mobileNumber || '',
        }
        const summaryData = {
            incomeType: data.summary
        }
        this.setState({
            incomeType: incomeTypeData,
            income: incomeData,
            additionalIncome: additionalIncomeData,
            incomeTax: incomeTaxData,
            commitments: commitmentsData,
            basicDetails: basicDetailsData,
            summary: summaryData
        })


        this.setState({slideIndex: slideIndex});
    }

    getApplicantById = (id) => {
        if (id === 0 || id === -1) {
            return this.props.loanData;
        } else {
            return this.props.coApplicant.find(obj => obj.id === id);
        }
    }
    handleChangeUserType = (e) => {

        let totalIncome = this.state.totalIncome;
        let totalIncomeCurrency = this.state.totalIncomeCurrency;

        let commission = this.state.income.commission;
        let commissionCurrency = this.state.income.commissionCurrency;
        let basicSalaryCurrency = this.state.income.basicSalaryCurrency;
        let basicSalary = this.state.income.basicSalary;
        let fixedAllowanceCurrency = this.state.income.fixedAllowanceCurrency;
        let fixedAllowance = this.state.income.fixedAllowance;

        if (e.userType === 'Self' && e.userType !== this.state.userType) {
            /**initiate */
            commission = '';
            commissionCurrency = 'MYR';
            basicSalaryCurrency = 'MYR';
            basicSalary = '';
            fixedAllowanceCurrency = 'MYR';
            fixedAllowance = '';

        } else if (e.userType === 'Salaried' && e.userType !== this.state.userType) {
            totalIncome = '';
            totalIncomeCurrency = 'MYR';
        }
        this.setState({
            ...this.state,
            userType: e,
            income: {
                ...this.state.income,
                totalIncome,
                totalIncomeCurrency,
                commission,
                commissionCurrency,
                fixedAllowance,
                fixedAllowanceCurrency,
                basicSalary,
                basicSalaryCurrency,
            },
        });
    }
    handleChangeIncomeType = (e) => {

        let commission = this.state.income.commission;
        let commissionCurrency = this.state.income.commissionCurrency;
        let basicSalaryCurrency = this.state.income.basicSalaryCurrency;
        let basicSalary = this.state.income.basicSalary;
        let fixedAllowanceCurrency = this.state.income.fixedAllowanceCurrency;
        let fixedAllowance = this.state.income.fixedAllowance;

        if (e.incomeType === "Basic Salary") {
            /**initiate */
            commission = '';
            commissionCurrency = 'MYR';
            fixedAllowance = '';
            fixedAllowanceCurrency = 'MYR';

        } else if (e.incomeType === "Pure Commission") {
            basicSalary = '';
            basicSalaryCurrency = 'MYR';
            fixedAllowance = '';
            fixedAllowanceCurrency = 'MYR';
        }

        this.setState({
            ...this.state,
            incomeType: e,
            income: {
                ...this.state.income,
                commission,
                commissionCurrency,
                fixedAllowance,
                fixedAllowanceCurrency,
                basicSalary,
                basicSalaryCurrency,
            },
        });
    }
    onInputChange = (stepKey, key, value) => {
        this.setState({
            ...this.state,
            [stepKey]: {
                ...this.state[stepKey],
                [key]: value,
            }
        });
    }

    renderSlide = (sliderIndex) => {
        let {
            circularProgressValue, userType, incomeType, slideIndex,
            eligibilityCheckData, validateEmail, coApplicantStatus,
            removeDetailStatus, editData, editStatus
        } = this.state;
        const dataInStore = this.getApplicantById(this.state.coApplicantId) || {};
        // const dataInStore = this.getApplicantById(this.props.selectedId);

        switch (sliderIndex) {
            case 0:
                return <Slide1 resetLoanData={this.props.resetLoanData}
                               translate={this.props.t}/>;
            case 1:
                return <Slide2
                    selectedType={(e) => this.handleChangeUserType(e)}
                    coApplicantStatus={coApplicantStatus}
                    removeDetail={removeDetailStatus}
                    editData={editData}
                    editStatus={editStatus}
                    dataInStore={dataInStore}
                    translate={this.props.t}
                />
            case 2: {
                return userType.userType === 'Salaried' ?
                    <Slide3
                        selectedType={(e) => this.handleChangeIncomeType(e)}
                        coApplicantStatus={coApplicantStatus}
                        editData={this.state.incomeType}
                        editStatus={editStatus}
                        dataInStore={dataInStore}
                        translate={this.props.t}
                    />
                    :
                    <div/>
            }
            case 3:
                return <Slide4
                    incomeType={incomeType.incomeType}
                    userType={userType.userType}
                    slideIndex={sliderIndex}
                    onInputChange={(key, value) => this.onInputChange('income', key, value)}
                    dataInStore={dataInStore}
                    returnedData={(e) => {
                        this.setState({
                            income: {
                                ...this.state.income,
                                ...e
                            }
                        });
                        this.setState({
                            editData: {
                                ...this.state.editData,
                                ...e
                            }
                        });
                    }}
                    coApplicantStatus={coApplicantStatus}
                    editData={dataInStore}//{editData}
                    time={new Date().getTime()}
                    editStatus={editStatus}
                    translate={this.props.t}
                />
            case 4:
                return <Slide5
                    slideIndex={sliderIndex}
                    onInputChange={(key, value) => this.onInputChange('additionalIncome', key, value)}
                    dataInStore={dataInStore}
                    returnedData={(e) => {
                        this.setState({
                            additionalIncome: {
                                ...this.state.additionalIncome,
                                ...e
                            }
                        });
                        this.setState({
                            editData: {
                                ...this.state.editData,
                                ...e
                            }
                        });
                    }}
                    coApplicantStatus={coApplicantStatus}
                    time={new Date().getTime()}
                    editData={editData}
                    editStatus={editStatus}
                    translate={this.props.t}
                />
            case 5: {
                return userType.userType === 'Salaried' ?
                    <Slide6
                        slideIndex={sliderIndex}
                        onInputChange={(key, value) => this.onInputChange('incomeTax', key, value)}
                        dataInStore={dataInStore}
                        returnedData={(e) => {
                            this.setState({
                                incomeTax: {
                                    ...this.state.incomeTax,
                                    ...e
                                }
                            });
                            this.setState({
                                editData: {
                                    ...this.state.editData,
                                    ...e
                                }
                            });
                        }}
                        coApplicantStatus={coApplicantStatus}
                        editData={this.state.incomeTax}
                        editStatus={editStatus}
                        translate={this.props.t}
                    />
                    :
                    <div/>
            }
            case 6:
                return <Slide7
                    slideIndex={sliderIndex}
                    onInputChange={(key, value) => this.onInputChange('commitments', key, value)}
                    dataInStore={dataInStore}
                    returnedData={(e) => {
                        this.setState({
                            commitments: {
                                ...this.state.commitments,
                                ...e
                            }
                        });
                        this.setState({
                            editData: {
                                ...this.state.editData,
                                ...e
                            }
                        });
                    }}
                    coApplicantStatus={coApplicantStatus}
                    editData={this.state.commitments}
                    editStatus={editStatus}
                    translate={this.props.t}
                />
            case 7:
                return <Slide8
                    slideIndex={sliderIndex}
                    onInputChange={(key, value) => this.onInputChange('basicDetails', key, value)}
                    dataInStore={dataInStore}
                    returnedData={(e) => {
                        this.setState({
                            basicDetails: {
                                ...this.state.basicDetails,
                                ...e
                            }
                        });
                        this.setState({
                            editData: {
                                ...this.state.editData,
                                ...e
                            }
                        });
                    }}
                    coApplicantStatus={coApplicantStatus}
                    validateEmail={validateEmail}
                    editStatus={editStatus}
                    editData={this.state.basicDetails}
                    translate={this.props.t}
                />
            case 8:
                return <Slide9
                    slideIndex={sliderIndex}
                    returnedData={(e) => this.setState({summary: e})}
                    coApplicantStatus={coApplicantStatus}
                    data={this.props.loanData}
                    editStatus={editStatus}
                    coApplicant={this.props.coApplicant}
                    editData={this.state.summary}
                    slide9Radio={-1}
                    editInfoClicked={(data, title, type) => this._editInfoClicked(data, title, type, 1)}
                    translate={this.props.t}
                />
            case 9:
                return <Slide10
                    returnedData={(e) => {
                        e === true &&
                        this.setState({slideIndex: this.state.slideIndex - 1})
                    }}
                    eligibilityCheckData={eligibilityCheckData}
                    coApplicantStatus={coApplicantStatus}
                    translate={this.props.t}
                />
            default:
                return <><p>unknown index</p></>;

        }
    }
    nextKeyUpHandle = (e) => {
        if (e.key === 'Tab') {
            setTimeout(() => {
                this._next();
            }, 250) /**0.25 second */
        }
    }
    changeLanguage = (lng) => {
        this.props.i18n.changeLanguage(lng);

        this.setState({showMenu: false, languageShow: lng.toUpperCase()}, () => {
            document.removeEventListener('click', this.closeMenu);
        });
    }

    handleClick = event => {
        this.setState({anchorEl: true})
    };

    handleClose = () => {
        this.setState({anchorEl: false})
    };

    showMenu(event) {
        event.preventDefault();

        this.setState({showMenu: true}, () => {
            document.addEventListener('click', this.closeMenu);
        });
    }

    closeMenu(event) {

        if (!this.dropdownMenu.contains(event.target)) {

            this.setState({showMenu: false}, () => {
                document.removeEventListener('click', this.closeMenu);
            });

        }
    }

    render() {
        const {auth} = this.props;
        let {
            circularProgressValue,
            userType,
            incomeType,
            slideIndex,
            eligibilityCheckData,
            validateEmail,
            coApplicantStatus,
            removeDetailStatus,
            editData,
            editStatus
        } = this.state;
        let sliderIndex = slideIndex;

        const settings = {
            infinite: false,
            touchMove: false,
            speed: 400,
            slidesToShow: 1,
            slidesToScroll: 1,
            beforeChange: (current, next) => {
                this.setState({slideIndex: next, circularProgressValue: Math.ceil((slideIndex / 10) * 100)})
            },
        };

        let chengedHeight = '550px';
        let slickScroll = '';
        if (sliderIndex === 0 ||
            sliderIndex === 1 ||
            sliderIndex === 2 ||
            sliderIndex === 3 ||
            sliderIndex === 5) {
            chengedHeight = '550px';
            slickScroll = '';
        } else {
            chengedHeight = '100%';
            slickScroll = 'slickScroll';
        }
        return (
            <>
                {
                    Util.clientTheme() === 'spsetia' &&
                    <Header
                        leftLogoName="spsetia/SPSETIA-left.svg"
                        rightLogoName="spsetia/SPSETIA-right.svg"
                        rightLogoLink="https://spsetia.com/en-us/"
                    />
                }
                <section className="container">

                    <Form className="form">

                        {
                            auth.debugger &&
                            <>
                                <div className="changeLanguageBox" onClick={this.showMenu}>
                                    {this.state.languageShow} <i className="fa fa-sort-down changeLanguageBoxIcon"></i>

                                </div>
                                {
                                    this.state.showMenu
                                        ? (
                                            <div
                                                className="menu-language"
                                                ref={(element) => {
                                                    this.dropdownMenu = element;
                                                }}
                                            >
                                                <div onClick={() => this.changeLanguage('en')}>English</div>
                                                <div onClick={() => this.changeLanguage('cn')}>中文</div>
                                            </div>
                                        )
                                        : (
                                            null
                                        )
                                }
                            </>
                        }

                        <CarouselEligibilityCheck className="carousel-eligibility-check">
                            <div id='loan-slide'
                                 className={auth.isVerified === false || auth.isFailed ? 'message' : ''}>
                                {
                                    auth.isLoading
                                        ? <Loader/> : auth.isFailed
                                            ? <p>Error connecting to server</p> : auth.isVerified !== true
                                                ? <p>Access Denied</p> : this.renderSlide(this.state.slideIndex)
                                }
                            </div>
                        </CarouselEligibilityCheck>
                        <div id='loan-footer'>
                            <PoweredBy className="poweredBy">
                                <div>{this.props.t('poweredBy')}</div>
                                <img className="poweredByImage" src={require('../../assets/images/finology-logo.png')}/>
                            </PoweredBy>
                            {
                                auth.isVerified ?
                                    <EligibilityControls className="eligibility-controls">
                                        {
                                            sliderIndex === 0 ?
                                                <button id="next" className="eligibility-btn"
                                                        style={{fontWeight: 'bold'}}
                                                    // autoFocus={true}
                                                        onClick={this._next}
                                                        onKeyUp={(e) => this.nextKeyUpHandle(e)}>
                                                    {this.props.t('button.begin')}
                                                    <i className="fa fa-long-arrow-right"></i>
                                                </button>
                                                :
                                                sliderIndex < 9 &&

                                                <>
                                                    <button id="previous" className="eligibility-btn"
                                                            onClick={this._previous}>
                                                        <i className="fa fa-long-arrow-left"></i> {this.props.t('button.previous')}
                                                    </button>
                                                    {
                                                        this.state.waitForResStatus ?
                                                            <button id="next" className="eligibility-btn">
                                                                <div class="spinner">
                                                                    <div class="bounce1"></div>
                                                                    <div class="bounce2"></div>
                                                                    <div class="bounce3"></div>
                                                                </div>
                                                            </button>
                                                            :
                                                            <button id="next" className="eligibility-btn"
                                                                    onClick={this._next}
                                                                    onKeyUp={(e) => this.nextKeyUpHandle(e)}
                                                            >
                                                                {
                                                                    (sliderIndex === 8 && this.state.summary['co-applicant'] === 'No') ?
                                                                        this.props.t('button.submit')
                                                                        :
                                                                        <>
                                                                            {this.props.t('button.next')}
                                                                            <i className="fa fa-long-arrow-right"></i>
                                                                        </>
                                                                }

                                                            </button>
                                                    }
                                                </>
                                        }

                                    </EligibilityControls> : <></>
                            }
                        </div>
                    </Form>

                    <ToastContainer/>
                    <ReactTooltip
                        place="bottom"
                        type="dark"
                        effect="solid"
                        className='tooltip'
                    />
                </section>
            </>
        )
    }
}


Home.propTypes = {
    updateUserData: PropTypes.func.isRequired,
    eligibilityCheckAction: PropTypes.func.isRequired,
    addNewApplicant: PropTypes.func.isRequired,
    updateCoApplicantData: PropTypes.func.isRequired,
    eligibilityEmailAction: PropTypes.func.isRequired,
    resetStoreAction: PropTypes.func.isRequired,
    getIPAction: PropTypes.func.isRequired,
    loanData: PropTypes.object.isRequired,
    coApplicant: PropTypes.array.isRequired
};

Home.defaultProps = {};

/* Export Component =============================== */
export default withTranslation()(Home);