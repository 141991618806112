import React from 'react';

import { Slide1bg, Slide1text } from "../styled";

class Slide1 extends React.Component{
    
    componentDidMount() {
        this.props.resetLoanData();
    }
    render(){
        const { translate } = this.props;
        return(
            <div className="firstTitle">
                <Slide1bg className="slide1bg" />
                <Slide1text className="slide1text">
                    <p>
                        {translate('slide1.0')} 
                    </p>
                    <p>
                        {translate('slide1.1')} 
                    </p>
                    <p>
                        {translate('slide1.2')} 
                    </p>
                    <p>
                        {translate('slide1.3')} 
                    </p>
                </Slide1text>
          </div>

        )
     }
}


/* Export Component =============================== */
export default Slide1;