import React from 'react';
import PropTypes from 'prop-types';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PhoneInput from 'react-phone-input-2';

import Select from '../../../components/Select/index';
import InputText from '../../../components/InputText/index';
import Util from '../../../lib/Util';
import RSelect from 'react-select'
import {clientTheme} from '../../../theme';

const client = Util.clientTheme();
const customStyles = {
    container: (provided) => {
        return {...provided};
    },
    option: (provided, state) => ({
        ...provided,
        zIndex: 99
    }),
    menuList: (provided) => {
        return {
            ...provided,
            height: 150
        }
    },
    control: (provided) => ({

        ...provided,
        borderRadius: 30,
        backgroundColor: '#F6F6F6',
        borderColor: '#F6F6F6'
    }),
}

class Slide7 extends React.Component {

    constructor(props) {
        super(props);

        const {dataInStore} = this.props;
        this.state = {
            value: this.props.editData.gender || '',
            phone: this.props.editData.mobileNumber || '',

            name: dataInStore.name || '',
            age: dataInStore.age || '',
            gender: dataInStore.gender || '',
            email: dataInStore.email || '',
            mobileNumber: dataInStore.mobileNumber || '',
        }

        this.age = [
            {label: '21', value: 21}, {label: '22', value: 22}, {label: '23', value: 23}, {
                label: '24',
                value: 24
            }, {label: '25', value: 25}, {label: '26', value: 26}, {label: '27', value: 27}, {
                label: '28',
                value: 28
            }, {label: '29', value: 29}, {label: '30', value: 30},
            {label: '31', value: 31}, {label: '32', value: 32}, {label: '33', value: 33}, {
                label: '34',
                value: 34
            }, {label: '35', value: 35}, {label: '36', value: 36}, {label: '37', value: 37}, {
                label: '38',
                value: 38
            }, {label: '39', value: 39}, {label: '40', value: 40},
            {label: '41', value: 41}, {label: '42', value: 42}, {label: '43', value: 43}, {
                label: '44',
                value: 44
            }, {label: '45', value: 45}, {label: '46', value: 46}, {label: '47', value: 47}, {
                label: '48',
                value: 48
            }, {label: '49', value: 49}, {label: '50', value: 50},
            {label: '51', value: 51}, {label: '52', value: 52}, {label: '53', value: 53}, {
                label: '54',
                value: 54
            }, {label: '55', value: 55}, {label: '56', value: 56}, {label: '57', value: 57}, {
                label: '58',
                value: 58
            }, {label: '59', value: 59}, {label: '60', value: 60},
            {label: '61', value: 61}, {label: '62', value: 62}, {label: '63', value: 63}, {
                label: '64',
                value: 64
            }, {label: '65', value: 65}, {label: '66', value: 66}, {label: '67', value: 67}, {
                label: '68',
                value: 68
            }, {label: '69', value: 69}, {label: '70', value: 70},
        ];

        this.radio = [
            {
                id: 0,
                label: 'Male'
            },
            {
                id: 1,
                label: 'Female'
            }
        ];

        this.data = {
            name: 0,
            age: 0,
            gender: '',
            email: 0,
            mobileNumber: 0
        }

        this.ageValidMsg = false;

        this.handleChange = this.handleChange.bind(this);
        this._changedSelect = this._changedSelect.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.editStatus && prevProps.editData.gender !== prevState.value) {
            this.setState({value: prevProps.editData.gender});
        }
        if (prevProps.editStatus && prevProps.editData.mobileNumber !== prevState.phone) {
            this.setState({phone: prevProps.editData.mobileNumber});
        }
    }

    handleChange(event) {
        this.setState({value: event.target.value})
        this.data['gender'] = event.target.value;
        this.props.returnedData(this.data);
        this.data = {}
    };

    _changedSelect(item, value) {
        this.data[item] = value;
        this.props.returnedData(this.data);
        this.data = {}
    }

    handleOnChange(value) {
        this.setState({phone: value})
        let phoneNum = (Util.removeChar(value)).replace(/\D/g, '');
        this.data['mobileNumber'] = phoneNum;
        this.props.returnedData(this.data);
        this.data = {}
    }

    handleGenderChange = (event) => {
        const key = 'gender'
        const value = event && event.target && event.target.value;
        this.setState({
            ...this.state,
            [key]: value,
        });
        ;
        this.props.onInputChange(key, value);
    };
    handlePhoneChange = (value) => {
        const key = 'mobileNumber';
        value = (Util.removeChar(value)).replace(/\D/g, '');

        this.setState({
            ...this.state,
            [key]: value,
        });
        this.props.onInputChange(key, value);
    }
    onInputChange = (key, value) => {
        if (key === 'age') {
            if (value === '70') {
                this.ageValidMsg = true;
            } else {
                this.ageValidMsg = false
            }
        }

        this.setState({
            ...this.state,
            [key]: value,
        });
        this.props.onInputChange(key, value);
    }
    handleKeyDown = (id, e) => {
        if (e.key === 'Enter') {
            this.handleGenderChange({target: {value: this.radio[id].label}});
        }
    }

    render() {
        let {validateEmail, translate} = this.props;
        return (

            <div>
                {/* <div className="topTitleLine" /> */}
                <h2>
                    {
                        this.props.coApplicantStatus ?
                            translate("slide8.title.co")
                            :
                            translate("slide8.title.main")
                    }

                </h2>

                <div style={{margin: '10px'}}>
                    <div className="selectInputBox">
                        <div className="row">
                            <div className="col-sm-3 col-xs-3 detailTitle">
                                {translate("slide8.placeHolder.name")} <i className="required-field"> *</i>
                            </div>
                            <div className="col-sm-9 col-xs-9">
                                <InputText
                                    id='name'
                                    value={this.state.name}
                                    onChange={(key, value) => this.onInputChange(key, value)}
                                    autoFocus={true}
                                    text
                                    placeholder={translate("slide8.placeHolder.name")}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="selectInputBox">
                        <div className="row">
                            <div className="col-sm-3 col-xs-3 detailTitle">
                                {translate("slide8.placeHolder.age")} <i className="required-field"> *</i>
                            </div>
                            <div className="col-sm-9 col-xs-9 select">
                                {client !== "uems" ? (
                                    <Select
                                        id='age'
                                        value={this.state.age}
                                        onChange={(key, value) => this.onInputChange(key, value)}
                                        data={this.age}
                                        initial={0}
                                        withDefaultLabel
                                        defaultLabel={translate("slide8.placeHolder.selectAge")}
                                    />
                                ) : <RSelect
                                    styles={customStyles}
                                    id='age'
                                    Clearable={false}
                                    onChange={(key, value) => {
                                        this.onInputChange("age", key.label)
                                    }}
                                    defaultValue={this.state.age?{label: this.state.age, value: this.state.age}:undefined}

                                    options={this.age}
                                    placeholder={translate("slide8.placeHolder.selectAge")}
                                />}

                                {
                                    this.ageValidMsg &&
                                    <p className="ageValidMsg">
                                        {translate("slide8.placeHolder.ageValidMsg")}
                                    </p>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="selectInputBox">
                        <div className="row">
                            <div className="col-sm-3 col-xs-3 detailTitle">
                                {translate("slide8.placeHolder.gender.gender")} <i className="required-field"> *</i>
                            </div>
                            <div className="col-sm-9 col-xs-9">
                                <RadioGroup value={this.state.gender} aria-label="position" row
                                            onChange={this.handleGenderChange}>
                                    <FormControlLabel
                                        tabIndex={0}
                                        onKeyDown={(e) => this.handleKeyDown(this.radio[0].id, e)}
                                        value={this.radio[0].label}
                                        control={<Radio color="default"/>}
                                        label={translate("slide8.placeHolder.gender.male")}
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        tabIndex={0}
                                        onKeyDown={(e) => this.handleKeyDown(this.radio[1].id, e)}
                                        value={this.radio[1].label}
                                        control={<Radio color="default"/>}
                                        label={translate("slide8.placeHolder.gender.female")}
                                        labelPlacement="end"
                                    />
                                </RadioGroup>
                            </div>
                        </div>
                    </div>

                    <div className="selectInputBox">
                        <div className="row">
                            <div className="col-sm-3 col-xs-3 detailTitle">
                                {translate("slide8.placeHolder.email")} <i className="required-field"> *</i>
                            </div>
                            <div className="col-sm-9 col-xs-9">
                                <InputText
                                    id='email'
                                    value={this.state.email}
                                    onChange={(key, value) => this.onInputChange(key, value)}
                                    text
                                    placeholder={translate("slide8.placeHolder.email")}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="selectInputBox">
                        <div className="row">
                            <div className="col-sm-3 col-xs-3 detailTitle">
                                {translate("slide8.placeHolder.phone")} <i className="required-field"> *</i>
                            </div>
                            <div className="row col-sm-9 col-xs-9 mobileResponsive" style={{'paddingRight': 0}}>
                                <div className="col-sm-12 col-xs-12" style={{'paddingRight': 0}}>
                                    <PhoneInput
                                        id='mobileNumber'
                                        value={this.state.mobileNumber}
                                        onChange={this.handlePhoneChange}
                                        masks={{my: '+.. ..........'}}
                                        defaultCountry={'my'}
                                        enableSearchField
                                        disableSearchIcon
                                        ref={c => this.phoneInput = c}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        )
    }
}


/* Export Component =============================== */
export default Slide7;