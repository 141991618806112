/* *
 * Global Util Functions

 * */
import { clientsName } from '../theme';
import env from '../.env.js';

const Util = {

	validateEmail: email => /^([\w\.\-_]+)?\w+@[\w-_]+(\.\w+){1,}$/g.test(email),

	randomNumber: (min, max) => {
    	return Math.floor(Math.random()*(max-min+1)+min);
	},

	removeChar: (char) => {
		return char.replace(/[^0-9]/, '');
	},

	removeCharWithoutDot: (char) => {
		return char.replace(/[^0-9^.]+/g, '');
	},

	validateMobile: (number) => {
		let status = false;
		if (number.length === 11 || number.length === 12) {
			status = true;
		}
		return status;
	},

	getQueryString: (url, parameter) => {
		const myParameter = parameter.replace(/[\[\]]/g, '\\$&');
		const regex = new RegExp(`[?&]${myParameter}(=([^&#]*)|&|#|$)`);
		const results = regex.exec(url);
		if (!results) return null;
		if (!results[2]) return '';
		return decodeURIComponent(results[2].replace(/\+/g, ' '));
	},

	convertNum: (str, comma = true) => {
		if (str === '' || str === undefined) return;

		let number = '';
		let numFlag = false;
		let strNum = str;
		

		if (str < 0) {
			strNum = Math.abs(strNum);
			numFlag = true;
		}

		number = strNum.toString().replace(/,/g,"");

		// add comma to number
		if (comma) {
			number = number.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
		}

		return number;
	},
	removeForwardSlash: (str) => {
		if(!str)
			return '';

		return str.replace(/\//g, '');
	},
	getDataFromUrl: (data) => {
		if(!data) {
			return '';
		}

		const d = data.pathname.split('/');
		const mySata = {
			hostname: data.host,
			code: d[d.length-2],
			token: d[d.length-1]
		}
		return mySata;
	},
	handleRequest: async (url, options) => {
		try{
		  const response = await fetch(url, options);
		  return response;
		}catch(e) {
		  throw new Error(e.message)
		}
	},
	handleResponse: async (response) => {
		if(response.ok || response.status === 401){
			return await response.json();	  
		}else {
		  const res = await response.json();
		  console.error('ERROR, SERVER RESPONSE: ', res);
		  throw new Error(res.message);
		}
	},

	clientTheme: () => {
		const defaultClient = 'default';
		const data = Util.getDataFromUrl(window.location);
		const hostname = data.hostname;
		const clientCodeMapper = env.client_code_mapper || [];

		let code = data.code.toLowerCase();
		clientCodeMapper.forEach((element) => {
			if (Object.values(element)[0] === hostname) {
				code = Object.keys(element)[0];
				return;
			}    
		});

		const client = clientsName.includes(code) ? code : defaultClient;
		return client;
	}

};

/* Export ================================ */
export default Util;
