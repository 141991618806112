
import styled from "styled-components";

export const RmWrapper = styled.div`
    border-bottom: 1px solid ${({ theme }) => theme.color.tertiary};
    color: #585858;
    padding: 9px 12px;
    text-align: center;
    height: 38px;

    @media (max-width: 680px) {
        padding: 9px 10px;
    }
    @media (max-width: 630px) {
        padding: 9px 9px;
    }
    @media (max-width: 613px) {
        padding: 9px 7px;
    }
    @media (max-width: 580px) {
        padding: 9px 6px;
    }
    @media (max-width: 575px) {
        padding: 9px 12px;
    }
      ${({theme}) => {
        if (theme.themeName && theme.themeName === "uems") {
          return `
                    border-radius:30px!important;
                    background-color:#F6F6F6!important;
                    border-color:#F6F6F6!important;                    
                    margin-right:5px
                `
        }
        return ``
      }}
`;