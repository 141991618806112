import Util from './lib/Util';

import defaultFavIcon from './assets/images/favicon.ico';
import default192 from './assets/images/default/finology-logo-192.png';
import default512 from './assets/images/default/finology-logo-512.png';

import setia192 from './assets/images/spsetia/setia-192.png';
import setia521 from './assets/images/spsetia/setia-512.png';
import spsetiaFavIcon from './assets/images/spsetia/favicon.ico';

import tcbFavIcon from './assets/images/favicon.ico';
import tcb192 from './assets/images/tcb/tropicana-192.png';
import tcb512 from './assets/images/tcb/tropicana-512.png';

const client = Util.clientTheme();


const manifest = {
    default: {
        "name": "Finology Sdn Bhd Mortgage Loan Calculator",
        "short_name": "Finology Mortgage Loan Calculator",
        "start_url": "/",
        "Scope": "/",
        "display": "standalone",
        "theme_color": "#000000",
        "background_color": "#ffffff",
        "icons": [
            {
                "src": tcbFavIcon,
                "sizes": "64x64 32x32 24x24 16x16",
                "type": "image/x-icon"
            },
            {
                "src": tcb192,
                "sizes": "192x192",
                "type": "image/png"
            },
            {
                "src": tcb512,
                "sizes": "512x512",
                "type": "image/png"
            }
        ]
    },
	spsetia: {
        "name": "S P Setia Mortgage Loan Calculator",
        "short_name": "S P Setia Mortgage Loan Calculator",
        "start_url": "/",
        "Scope": "/",
        "display": "standalone",
        "theme_color": "#000000",
        "background_color": "#ffffff",
        "icons": [
            {
                "src": spsetiaFavIcon,
                "sizes": "64x64 32x32 24x24 16x16",
                "type": "image/x-icon"
            },
            {
                "src": setia192,
                "sizes": "192x192",
                "type": "image/png"
            },
            {
                "src": setia521,
                "sizes": "512x512",
                "type": "image/png"
            }
        ]
    },
	tcb: {
        "name": "Tropicana Mortgage Loan Calculator",
        "short_name": "Tropicana Mortgage Loan Calculator",
        "start_url": "/",
        "Scope": "/",
        "display": "standalone",
        "theme_color": "#000000",
        "background_color": "#ffffff",
        "icons": [
            {
                "src": tcbFavIcon,
                "sizes": "64x64 32x32 24x24 16x16",
                "type": "image/x-icon"
            },
            {
                "src": tcb192,
                "sizes": "192x192",
                "type": "image/png"
            },
            {
                "src": tcb512,
                "sizes": "512x512",
                "type": "image/png"
            }
        ]
    }
}


const stringManifest = JSON.stringify(manifest[client]);
const blob = new Blob([stringManifest], {type: 'application/json'});
const manifestURL = URL.createObjectURL(blob);
document.querySelector('#dynamic-manifest').setAttribute('href', manifestURL);

