import React from 'react';
import { ClipLoader } from 'react-spinners';

import Util from '../../lib/Util';

import { clientTheme } from '../../theme';

const client = Util.clientTheme();

const Loader = ({size=3}) => {
  return(
    <div id='loader'>
      <ClipLoader 
        sizeUnit={"rem"}
        size={size}
        color={clientTheme[client].color.primary}
    />
    </div>  
  )
}

export default Loader;