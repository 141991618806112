import styled from "styled-components";

export const InputTextItem = styled.input`
  width: 100%;
  height: 38px;
  padding: 6px 11px;
  border: 0;
  border-bottom: 1px solid ${({theme}) => theme.color.tertiary};
  border-radius: 0;
  color: ${({theme}) => theme.themeName === "uems" ? theme.color.tertiary : undefined};
  &:focus {
    box-shadow: none !important;
    border-bottom: 3px solid ${({theme}) => theme.color.tertiary};
    outline: 0 !important;
  }

  &:active {
    box-shadow: none !important;
    border-bottom: 3px solid ${({theme}) => theme.color.tertiary};
    outline: 0 !important;
  }

  &:visited {
    box-shadow: none !important;
    border-bottom: 1px solid ${({theme}) => theme.color.tertiary};
    outline: 0 !important;
  }
  
`;

export const InputUp = styled.i`
  position: absolute;
  right: 20px;
  top: 0px;
  color: ${({theme}) => theme.color.tertiary};
  cursor: pointer;
`;

export const InputDown = styled.i`
  position: absolute;
  right: 20px;
  top: 15px;
  color: ${({theme}) => theme.color.tertiary};
  cursor: pointer;
`;