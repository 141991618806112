import React from 'react';
import PropTypes from 'prop-types';

import Select from '../../../components/Select/index';
import InputText from '../../../components/InputText/index';
import Tooltip from '../../../components/Tooltip/index';
import Util from '../../../lib/Util';

const client = Util.clientTheme();

class Slide4 extends React.Component {

    constructor(props) {
        super(props);

        const {dataInStore} = this.props;
        this.state = {
            totalIncomeCurrency: dataInStore.totalIncomeCurrency || 'MYR',
            totalIncome: dataInStore.totalIncome || '',
            basicSalaryCurrency: dataInStore.basicSalaryCurrency || 'MYR',
            basicSalary: dataInStore.basicSalary || '',
            fixedAllowanceCurrency: dataInStore.fixedAllowanceCurrency || 'MYR',
            fixedAllowance: dataInStore.fixedAllowance || '',
            commissionCurrency: dataInStore.commissionCurrency || 'MYR',
            commission: dataInStore.commission || '',
        }

        this.currency = [
            {label: 'AED'},
            {label: 'AUD'},
            {label: 'BND'},
            {label: 'EUR'},
            {label: 'GBP'},
            {label: 'HKD'},
            {label: 'IDR'},
            {label: 'INR'},
            {label: 'JPY'},
            {label: 'MYR'},
            {label: 'NZD'},
            {label: 'QAR'},
            {label: 'RMB'},
            {label: 'SGD'},
            {label: 'THB'},
            {label: 'USD'},
        ];

        this.data = {};

        this._changedSelect = this._changedSelect.bind(this);
    }
    _changedSelect(item, value) {
        this.data[item] = value;
        this.props.returnedData(this.data);
        this.data = {};
    }
    onInputChange = (key, value) => {
        this.setState({
            ...this.state,
            [key]: value,
        });
        this.props.onInputChange(key, value);
    }

    render() {

        let {incomeType, userType, translate} = this.props;
        let mobileSize = client === "uems" ? {label: 4, input: 8} : {label: 3, input: 9}
        if (userType === 'Self') {
            return (
                <div>
                    {/* <div className="topTitleLine" /> */}
                    <h2>
                        {
                            this.props.coApplicantStatus ?
                                translate("slide4.title.self.co")
                                :
                                translate("slide4.title.self.main")
                        }
                        <Tooltip
                            parent="TooltipBform"
                            text={translate("slide4.tooltip.selfTitle")}
                        />
                    </h2>

                    <div className="selectInputBoxOld">
                        <div className="fieldTitle">
                            <span>{translate("slide4.subtitle.self")}</span>
                            <i className="required-field"> *</i>
                        </div>
                        <div className="row">
                            <div className={`col-sm-2 col-xs-${mobileSize.label} slid4`}>
                                <Select
                                    id='totalIncomeCurrency'
                                    value={this.state.totalIncomeCurrency}
                                    onChange={(key, value) => this.onInputChange(key, value)}
                                    data={this.currency}
                                    initial={9}
                                />
                            </div>
                            <div className={`col-sm-5 col-xs-${mobileSize.input} InputTextWithFixedBox`}>
                                <InputText
                                    id='totalIncome'
                                    value={this.state.totalIncome}
                                    onChange={(key, value) => this.onInputChange(key, value)}
                                    placeholder={translate("slide4.placeHolder.total")}
                                    autoFocus={true}
                                />
                            </div>
                        </div>
                    </div>

                </div>
            )
        } else if (userType === 'Salaried' && incomeType) {
            return (
                <div>
                    {/* <div className="topTitleLine" /> */}
                    <h2>
                        {
                            this.props.coApplicantStatus ?
                                translate("slide4.title.salaried.co")
                                :
                                translate("slide4.title.salaried.main")
                        }
                    </h2>

                    {
                        (incomeType === 'Basic Salary' || incomeType === 'Basic Salary + Variable Income / Commission') &&
                        <div className="selectInputBoxOld">
                            <div className="fieldTitle">
                                <span>{translate("slide4.placeHolder.basic")}</span>
                                <i className="required-field"> *</i>
                            </div>
                            <div className="row">
                                <div className={`col-sm-2 col-xs-${mobileSize.label} slid4`}>
                                    <Select
                                        id='basicSalaryCurrency'
                                        value={this.state.basicSalaryCurrency}
                                        onChange={(key, value) => this.onInputChange(key, value)}
                                        data={this.currency}
                                        initial={9}
                                    />
                                </div>
                                <div className={`col-sm-5 col-xs-${mobileSize.input} InputTextWithFixedBox`}>
                                    <InputText
                                        id='basicSalary'
                                        value={this.state.basicSalary}
                                        onChange={(key, value) => this.onInputChange(key, value)}
                                        placeholder={translate("slide4.placeHolder.basic")}
                                        autoFocus={true}
                                    />
                                </div>
                            </div>
                        </div>
                    }

                    {
                        (incomeType === 'Basic Salary' || incomeType === 'Basic Salary + Variable Income / Commission') &&
                        <div className="selectInputBoxOld">
                            <div className="fieldTitle">
                                <span>{translate("slide4.placeHolder.fixed")}</span>
                            </div>
                            <div className="row">
                                <div className={`col-sm-2 col-xs-${mobileSize.label} slid4`}>
                                    <Select
                                        id='fixedAllowanceCurrency'
                                        value={this.state.fixedAllowanceCurrency}
                                        onChange={(key, value) => this.onInputChange(key, value)}
                                        data={this.currency}
                                        initial={9}
                                    />
                                </div>
                                <div className={`col-sm-5 col-xs-${mobileSize.input} InputTextWithFixedBox`}>
                                    <InputText
                                        id='fixedAllowance'
                                        value={this.state.fixedAllowance}
                                        onChange={(key, value) => this.onInputChange(key, value)}
                                        placeholder={translate("slide4.placeHolder.fixed")}
                                    />
                                </div>
                            </div>
                        </div>
                    }

                    {
                        (incomeType === 'Basic Salary + Variable Income / Commission' || incomeType === 'Pure Commission') &&
                        <div className="selectInputBoxOld">
                            <div className="fieldTitle">
                                <span>{translate("slide4.placeHolder.commission")}</span>
                                <i className="required-field"> * </i>&nbsp;

                                <Tooltip
                                    parent="TooltipCommission"
                                    text={translate("slide4.tooltip.commission")}
                                />
                            </div>
                            <div className="row">
                                <div className={`col-sm-2 col-xs-${mobileSize.label} slid4`}>
                                    <Select
                                        id='commissionCurrency'
                                        value={this.state.commissionCurrency}
                                        onChange={(key, value) => this.onInputChange(key, value)}
                                        data={this.currency}
                                        initial={9}
                                    />
                                </div>
                                <div className={`col-sm-5 col-xs-${mobileSize.input} InputTextWithFixedBox`}>
                                    <InputText
                                        id='commission'
                                        value={this.state.commission}
                                        onChange={(key, value) => this.onInputChange(key, value)}
                                        placeholder={translate("slide4.placeHolder.commission")}
                                        autoFocus={incomeType === 'Pure Commission' ? true : false}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                </div>

            )
        } else {
            return (
                <div/>
            )
        }
    }
}


/* Export Component =============================== */
export default Slide4;