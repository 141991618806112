import styled from "styled-components";

export const Slide1bg = styled.div`
  width: 95%;
  height: 400px;
  opacity: 0.8;
  filter: blur(1px);
  -webkit-filter: blur(1px);
  background-image: url(${({theme}) => theme.image.slide1bgImage});
  background-size: cover;
  background-repeat: no-repeat;
`;

export const Slide1text = styled.div`
  width: 95%;
  height: 100%;
  background: ${({theme}) => theme.color.slide1bg};
  bottom: 370px;
  right: -30px;
  position: relative;
  text-align: left;
  font-size: 23px;
  color: ${({theme}) => theme.color.white};
  padding: 100px;
  ${({theme}) => {
    if (theme.themeName && theme.themeName === "uems") {
      return `
                    bottom: 340px;
                    right: -40px;
                `
    }
    return ``
  }}
`;

export const Form = styled.div`
  width: 100%;
  margin: 3% auto;
  position: relative;
  z-index: 2;
  background: ${({theme}) => theme.color.white};

  > .display-widget {
    position: absolute;
    left: 0;
    min-width: 400px;
    z-index: 1;
    background: ${({theme}) => theme.color.black_light};
    padding: 14px;
    border-radius: 15px;
    top: -67px;
  }

  > .display-widget h1 {
    font-size: 22px;
    padding-left: 114px;
    margin-top: 10px;
    margin-bottom: 10px;
    color: ${({theme}) => theme.color.white};
    font-weight: bold;
  }
`;

export const CarouselEligibilityCheck = styled.div`
  width: 100%;
  border-radius: 5px;
  position: relative;
  border: 1px solid ${({theme}) => theme.color.gray};
  background-position: center;
  padding: 25px;
  z-index: 5;
`;

export const PoweredBy = styled.div`
  bottom: 25px;
  background: ${({theme}) => theme.color.white};
  position: absolute;
  left: 25px;
  overflow: hidden;
  z-index: 9999;
  font-size: 14px;
  color: ${({theme}) => theme.color.black_light};
`;

export const EligibilityControls = styled.div`
  bottom: 25px;
  background: ${({theme}) => theme.color.white};
  position: absolute;
  right: 25px;
  overflow: hidden;
  z-index: 9999;

  > .eligibility-btn {
    border: 1px solid ${({theme}) => theme.color.primary2} !important;
  }

  > #next {
    background: ${({theme}) => theme.color.primary2};
    outline: 0;
    color: ${({theme}) => theme.color.white};

    &:focus, &:active {
      background-color: darken(${({theme}) => theme.color.primary2}, $amount: 3);
    }
  }

  > #previous {
    background: ${({theme}) => theme.color.white};
    outline: 0;
    color: ${({theme}) => theme.color.primary2};
    padding: 13px 20px;
    margin: 0 20px;

    &:focus, &:active {
      background-color: darken(${({theme}) => theme.color.white}, $amount: 3);
    }
  }
`;

export const IconImage = styled.div`
  background: ${({theme}) => theme.themeName === "uems" ? "#E0E4F2" : theme.color.gray};
  border-radius: 3px;
  text-align: center;
  padding: 34px 15px;
  margin-bottom: 15px;
  cursor: pointer;
  display: block;
  -webkit-box-shadow: 0px 0px 22px -7px rgba(194, 194, 194, 0.82);
  -moz-box-shadow: 0px 0px 22px -7px rgba(194, 194, 194, 0.82);
  box-shadow: 0px 0px 22px -7px rgba(194, 194, 194, 0.82);

  > object {
    max-width: 70px;
    margin: 0 auto;
    pointer-events: none;
    filter: ${({theme}) => theme.color.svg};
  }

  ${({active, theme}) => active === 1 && `
        background: ${theme.color.tertiary2};
        -webkit-box-shadow: -1px 7px 22px -1px rgba(189,189,189,0.84);
        -moz-box-shadow: -1px 7px 22px -1px rgba(189,189,189,0.84);
        box-shadow: -1px 7px 22px -1px rgba(189,189,189,0.84);

        > object{
            filter: ${theme.color.svgActive};
        }
    `}
`;
export const IconImage2 = styled.div`
  background: ${({theme}) => theme.themeName === "uems" ? "#E0E4F2" : theme.color.gray};
  border-radius: 3px;
  text-align: center;
  padding: 34px 15px;
  margin-bottom: 15px;
  cursor: pointer;
  display: block;
  -webkit-box-shadow: 0px 0px 22px -7px rgba(194, 194, 194, 0.82);
  -moz-box-shadow: 0px 0px 22px -7px rgba(194, 194, 194, 0.82);
  box-shadow: 0px 0px 22px -7px rgba(194, 194, 194, 0.82);
  min-height: 145px;
  
  > object {
    max-width: 70px;
    margin: 0 auto;
    pointer-events: none;
    filter: ${({theme}) => theme.color.svg};
    ${({theme}) => {
      if (theme.themeName && theme.themeName === "uems") {
        return `
               max-width: 120px;
           `
      }
      return ``
    }}

  }

  ${({active, theme}) => active === 2 && `
        background: ${theme.color.tertiary2};
        -webkit-box-shadow: -1px 7px 22px -1px rgba(189,189,189,0.84);
        -moz-box-shadow: -1px 7px 22px -1px rgba(189,189,189,0.84);
        box-shadow: -1px 7px 22px -1px rgba(189,189,189,0.84);

        > object{
            filter: ${theme.color.svgActive};
        }
    `}
`;

export const ResultWidget = styled.div`
  background: ${({theme}) => theme.color.gray};
  border-radius: 3px;
  border: 1px solid ${({theme}) => theme.color.border};
  padding: 15px;
  min-height: 130px;
  height: 100%;
  display: grid;
  color: ${({theme}) => theme.color.black_light};
  -webkit-box-shadow: 0px 0px 22px -7px rgba(194, 194, 194, 0.82);
  -moz-box-shadow: 0px 0px 22px -7px rgba(194, 194, 194, 0.82);
  box-shadow: 0px 0px 22px -7px rgba(194, 194, 194, 0.82);
  ${({theme}) => {
    if (theme.themeName && theme.themeName === "uems") {
      return `
                    background-color:${theme.color.softBlue};                    
                    border-color:${theme.color.softBlue}!important;                    
                    -webkit-box-shadow: 0px 0px 0px 0px;
                    -moz-box-shadow: 0px 0px 0px 0px ;
                    box-shadow: 0px 0px 0px 0px;
                `
    }
    return ``
  }}
`;

export const Rate = styled.span`
  font-size: 43px;
  line-height: 1;
  display: inline-block;
  color: ${({theme}) => theme.color.secondary};
  ${({theme}) => {
    if (theme.themeName && theme.themeName === "uems") {
      return `
               color: ${theme.color.primary};
               font-size: 20px;
               margin-left:5px;
               font-weight:500;
           `
    }
    return ``
  }}
`;

export const MeterDisp = styled.div`
  height: 17px;
  position: relative;
  border-radius: 2px;
  width: 100%;
  overflow: hidden;
  background: ${({theme}) => theme.color.white};
  border: 1px solid ${({theme}) => theme.color.gray};
`;

export const MeterGood = styled.small`
  color: ${({theme}) => theme.color.black_dark};
  left: 0%;
  right: inherit;
  position: absolute;
  width: fit-content;
  font-size: 10px;
  z-index: 5;
`;

export const PriceDisp = styled.div`
  background: ${({theme}) => theme.color.gray};
  border-radius: 3px;
  border: 1px solid ${({theme}) => theme.color.border};
  color: ${({theme}) => theme.color.black_light};
  padding: 15px;
  min-height: 130px;
  height: 100%;
  -webkit-box-shadow: 0px 0px 22px -7px rgba(194, 194, 194, 0.82);
  -moz-box-shadow: 0px 0px 22px -7px rgba(194, 194, 194, 0.82);
  box-shadow: 0px 0px 22px -7px rgba(194, 194, 194, 0.82);
  ${({theme}) => {
    if (theme.themeName && theme.themeName === "uems") {
      return `
                    background-color:${theme.color.softBlue};                    
                    border-color:${theme.color.softBlue}!important;                    
                    -webkit-box-shadow: 0px 0px 0px 0px;
                    -moz-box-shadow: 0px 0px 0px 0px ;
                    box-shadow: 0px 0px 0px 0px;
                `
    }
    return ``
  }}

`;

export const SmallAmount = styled.small`
  font-size: 43px;
  line-height: 1;
  margin: 0 0 0 10px;
  color: ${({theme}) => theme.color.secondary};
  word-wrap: break-word;
  ${({theme}) => {
    if (theme.themeName && theme.themeName === "uems") {
      return `
                    color:${theme.color.primary};
                    font-size: 29px;                                        
                `
    }
    return ``
  }}
`;

export const RsltIstr = styled.p`
  font-size: 12px;
  margin-top: 10px !important;
  font-style: italic;
  color: ${({theme}) => theme.color.black_light};
`;

export const AffordabilityForm = styled.div`
  background: #ebebeb;
  border: 1px solid ${({theme}) => theme.color.border};
  margin: 10px 0 70px 0;
  color: ${({theme}) => theme.color.black_light};
  ${({theme}) => {
    if (theme.themeName && theme.themeName === "uems") {
      return `
                    background-color:${theme.color.softBlue};
                    border:0px!important;                                        
                `
    }
    return ``
  }}
`;
