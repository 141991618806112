import React from 'react';

import Tooltip from '../../../components/Tooltip/index';

import {IconImage, IconImage2} from "../styled";
import Util from '../../../lib/Util';
import {clientTheme} from '../../../theme';

const client = Util.clientTheme();

class Slide2 extends React.Component {

    constructor(props) {
        super(props);

        this.salariedDivRef = React.createRef();

        const {dataInStore} = this.props;
        this.state = {
            // type: -1, 
            type: dataInStore.userType === 'Salaried'
                ? 1 : (dataInStore.userType === 'Self' ? 2 : -1),
            salariedFocused: true,
            selfFocused: false,

        }

        this._selectType = this._selectType.bind(this);
    }

    componentDidMount() {
        this.focusDiv();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.editData !== prevProps.editData) {
            if (this.props.editData.userType === 'Salaried') {
                this.setState({type: 1});
            } else if (this.props.editData.userType === 'Self') {
                this.setState({type: 2});
            } else {
                this.setState({type: -1});
            }
        }
    }

    _selectType(type) {
        let typeName = 'Salaried';
        if (type === 2) {
            typeName = 'Self';
        }
        this.setState({type});

        const data = {
            userType: typeName
        }
        this.props.selectedType(data);
    }

    handleKeyUp = (id, e) => {
        switch (e.key) {
            case 'Tab':
                this.setState({
                    ...this.state,
                    selfFocused: id === 'Self',
                    salariedFocused: id === 'Salaried',
                })
                break;
            case 'Enter':
                const type = id === 'Salaried' ? 1 : 2;
                this._selectType(type)
                break;
            default:
                break;
        }
    }
    handleKeyDown = (id, e) => {
        switch (e.key) {
            case 'Tab':
                this.setState({
                    ...this.state,
                    selfFocused: id === 'Self' ? false : this.state.selfFocused,
                    salariedFocused: id === 'Salaried' ? false : this.state.salariedFocused,
                })
                break;
            default:
                break;
        }
    }
    focusDiv = () => {
        this.salariedDivRef.current.focus();
    }
    getSalariedEmployeeIcon = () => {
        if (client === "uems") {
            return require(`../../../assets/images/Salaried_Employee-uems${this.state.type === 1 ? '-selected' : ''}.svg`)
        }
        return require('../../../assets/images/Salaried_Employee-01.svg')
    }
    getSelfEmployedBusinessOwnerIcon = () => {
        if (client === "uems") {
            return require(`../../../assets/images/SelfEmployed_BusinessOwner-uems${this.state.type === 2 ? '-selected' : ''}.svg`)
        }
        return require('../../../assets/images/SelfEmployed_BusinessOwner-01.svg')
    }

    render() {
        let {type} = this.state;
        const {translate} = this.props;
        let styleOfSvg={}
        if(client==="uems"){
            styleOfSvg= {
                maxHeight: 90
            }
        }
        return (
            <div>
                {/* <div className="topTitleLine" /> */}
                <h2>
                    {
                        this.props.coApplicantStatus ?
                            translate("slide2.title.co")
                            :
                            translate("slide2.title.main")
                    }
                </h2>
                <div className="user-type">

                    <div className="iconBox"
                         ref={this.salariedDivRef}
                         tabIndex={0}
                         onKeyDown={(e) => this.handleKeyDown('Salaried', e)}
                         onKeyUp={(e) => this.handleKeyUp('Salaried', e)}
                    >
                        <IconImage
                            className="iconImage"
                            active={type}
                            onClick={() => this._selectType(1)}
                        >
                            <object
                                style={styleOfSvg}
                                type="image/svg+xml"
                                    data={this.getSalariedEmployeeIcon()}/>
                        </IconImage>
                        {/* <div 
                            className={ "iconImage " + 
                                    (type === 1 ? 'iconImageActive' : this.state.salariedFocused ? 'iconImageFocused' : '') } 
                                    onClick={() => this._selectType(1)}>
                            <object type="image/svg+xml" data={require('../../../assets/images/Salaried_Employee-01.svg')} />
                        </div> */}
                        <p>
                            {translate("slide2.type.salaried")}
                            <Tooltip
                                parent="TooltipSalaried"
                                text={translate("slide2.tooltip.salaried")}
                            />
                        </p>
                    </div>

                    <div className="iconBox"
                         tabIndex={0}
                         onKeyDown={(e) => this.handleKeyDown('Self', e)}
                         onKeyUp={(e) => this.handleKeyUp('Self', e)}
                    >
                        <IconImage2
                            className="iconImage"
                            active={type}
                            onClick={() => this._selectType(2)}
                        >
                            <object
                                style={styleOfSvg}
                                type="image/svg+xml"
                                    data={this.getSelfEmployedBusinessOwnerIcon()}/>
                        </IconImage2>
                        {/* <div className={"iconImage "+ (type === 2 ? 'iconImageActive' : this.state.selfFocused ? 'iconImageFocused' : '') } 
                             onClick={() => this._selectType(2)}>
                            <object type="image/svg+xml" data={require('../../../assets/images/SelfEmployed_BusinessOwner-01.svg')} />
                        </div> */}
                        <p>
                            {translate("slide2.type.self")}
                            <Tooltip
                                parent="TooltipSelf-Employed"
                                text={translate("slide2.tooltip.self")}
                            /> / {translate("slide2.type.business")}
                            <Tooltip
                                parent="TooltipSelfBusiness"
                                text={translate("slide2.tooltip.business")}
                            />
                        </p>
                    </div>

                </div>
            </div>

        )
    }
}


/* Export Component =============================== */
export default Slide2;