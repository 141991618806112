import React from 'react';
import PropTypes from 'prop-types';
import ToolTip from 'react-portal-tooltip';

import './style.scss';
import Util from '../../lib/Util';

import {clientTheme} from '../../theme';

const client = Util.clientTheme();


let style = {
    style: {
        padding: 20,
        background: clientTheme[client].color.secondary2,
        color: '#fff',
        'max-width': '250px',
        'min-height': '20px',
        'font-size': '12px',
    },
    arrowStyle: {
        color: clientTheme[client].color.secondary2,
        borderColor: false
    }
}
let customstyle = {
    style: {
        padding: 20,
        background: clientTheme[client].color.secondary2,
        color: '#fff',
        'max-width': '700px',
        'min-height': '20px',
    },
    arrowStyle: {
        color: clientTheme[client].color.secondary2,
        borderColor: false
    },
}

class Tooltip extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isTooltipActive: false
        }

    }

    showTooltip() {
        this.setState({isTooltipActive: true})
    }

    hideTooltip() {
        this.setState({isTooltipActive: false})
    }


    _onChange(e) {
        this.setState({
            value: e.target.value
        });
        this.props.selectedItem(e.target.value);
    }

    render() {

        let {parent, title, text, table, translate} = this.props;
        return (
            <>
                <span
                    id={parent}
                    className={`${client === "uems" ? "tooltipMarkUems" : "tooltipMark"}`}
                    onMouseEnter={this.showTooltip.bind(this)}
                    onClick={this.showTooltip.bind(this)}
                    onMouseLeave={this.hideTooltip.bind(this)}
                >
                </span>
                {
                    table ?
                        <ToolTip style={customstyle} parent={`#${parent}`} active={this.state.isTooltipActive}
                                 position="bottom" arrow="right">
                            <div className="rateTooltip">
                                <div
                                    className="rateTooltipLeft ratingStatusExcellent">{translate("slide10.tooltip.excellent")}</div>
                                <div className="rateTooltipRight">{translate("slide10.tooltip.excellentText")}</div>
                            </div>
                            <div className="rateTooltip">
                                <div
                                    className="rateTooltipLeft ratingStatusGood">{translate("slide10.tooltip.good")}</div>
                                <div className="rateTooltipRight">{translate("slide10.tooltip.goodText")}</div>
                            </div>
                            <div className="rateTooltip">
                                <div
                                    className="rateTooltipLeft ratingStatusFair">{translate("slide10.tooltip.fair")}</div>
                                <div className="rateTooltipRight">{translate("slide10.tooltip.fairText")}</div>
                            </div>
                            <div className="rateTooltip">
                                <div
                                    className="rateTooltipLeft ratingStatusPoor">{translate("slide10.tooltip.poor")}</div>
                                <div className="rateTooltipRight">{translate("slide10.tooltip.poorText")}</div>
                            </div>
                            <div className="rateTooltip">
                                <div
                                    className="rateTooltipLeft ratingStatusNotEligibile">{translate("slide10.tooltip.notEligible")}</div>
                                <div className="rateTooltipRight">{translate("slide10.tooltip.notEligibleText")}</div>
                            </div>
                        </ToolTip>
                        :
                        <ToolTip style={style} parent={`#${parent}`} active={this.state.isTooltipActive}
                                 position="bottom" arrow="center">
                            {
                                title &&
                                <div className="tooltipTitle">
                                    {title}
                                    <br/>
                                </div>
                            }
                            {text}
                        </ToolTip>
                }
            </>
        )
    }
}


Tooltip.propTypes = {
    table: PropTypes.bool
};

Tooltip.defaultProps = {
    table: false
};

/* Export Component =============================== */
export default Tooltip;