/**
 * API Config
 *
 * */
import env from '../.env.js';

export default {
    hostname: env.url,
    endpoints: [
        { key: 'eligibilityCheck'         , url: '/api/v1/home-loan-eligibility-check'    , method: 'POST'     },
        { key: 'eligibilityEmail'         , url: '/api/v1/email_loan_eligibility'         , method: 'POST'     },
        { key: 'getIP'         , url: '/api/v1/server'         , method: 'GET'     },
        { key: 'authorization' , url: '/api/v1/verify' }
    ],

};
