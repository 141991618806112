import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';
import { FormControl, SelectDown } from "./styled";
class Select extends React.Component{
    
    constructor(props) {
        super(props);

        this.state = {
            value: this.props.data[this.props.initial].label || 'MYR'
        }


        this._onChange = this._onChange.bind(this);

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.value !== prevProps.value) {
            this.setState({ value: this.props.value })
        }
    }

    _onChange(e) {
        const key = e && e.target && e.target.id;
        const value = e && e.target && e.target.value;
        this.props.onChange(key, value);
    }
    render(){

        let { data, label, isRequired } = this.props;
        const { value } = this.state;
        return(
            <div className="selectBoxForm">
                {
                    label &&
                        <>
                        <label htmlFor={label} >{label}</label>
                        {
                            isRequired &&
                                <i className="required-field"> *</i>
                        }
                        </>
                }
                <FormControl 
                    id={this.props.id}
                    value={this.props.value}
                    onChange={(e) => this._onChange(e)}

                    ref={c => (this.mySelect = c)} 
                    className="form-control"
                >
                    {
                        this.props.withDefaultLabel &&
                            <option value="" selected disabled>{this.props.defaultLabel}</option>
                    }
                    {data.map(item => {
                        return <option value={item.label} key={item.label} >{item.label}</option>
                    })}
                </FormControl>
                <SelectDown className="fa fa-chevron-down selectDown"></SelectDown>
            </div>

        )
     }
}


Select.propTypes = {
    data: PropTypes.array,
    label: PropTypes.string,
    initial: PropTypes.number,
    isRequired: PropTypes.bool
};

Select.defaultProps = {
    data: [],
    label: '',
    initial: 0,
    isRequired: false
};

/* Export Component =============================== */
export default Select;