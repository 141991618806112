import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';
import { RmWrapper } from "./styled";

class FixedBox extends React.Component{
    
    constructor(props) {
        super(props);

        this.state = {
        }

    }

    render(){

        let { label } = this.props;
        return(
            <RmWrapper className="rm-wrapper">
                {label}
            </RmWrapper>

        )
     }
}


FixedBox.propTypes = {
    label: PropTypes.string
};

FixedBox.defaultProps = {
    label: 'MYR'
};

/* Export Component =============================== */
export default FixedBox;