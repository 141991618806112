import React from 'react';
import PropTypes from 'prop-types';

import FixedBox from '../../../components/FixedBox/index';
import InputText from '../../../components/InputText/index';
import Tooltip from '../../../components/Tooltip/index';

class Slide7 extends React.Component{
    
    constructor(props) {
        super(props);

        const {dataInStore} = this.props;
        this.state = {
            homeLoan: dataInStore.homeLoan  || '',
            carLoan: dataInStore.carLoan || '',
            personalLoan: dataInStore.personalLoan || '',
            PTPTN: dataInStore.PTPTN || '',
            otherLoans: dataInStore.otherLoans || '',
            creditCard: dataInStore.creditCard || '',
            overdraft: dataInStore.overdraft || '',
        }

        this.currency = [
            { label: 'AED' },
            { label: 'AUD' },
            { label: 'BND' },
            { label: 'EUR' },
            { label: 'GBP' },
            { label: 'HKD' },
            { label: 'IDR' },
            { label: 'INR' },
            { label: 'JPY' },
            { label: 'MYR' },
            { label: 'NZD' },
            { label: 'QAR' },
            { label: 'RMB' },
            { label: 'SGD' },
            { label: 'THB' },
            { label: 'USD' },
        ];

        this.data = {
            homeLoan: 0,
            carLoan: 0,
            personalLoan: 0,
            PTPTN: 0,
            otherLoans: 0,
            creditCard: 0,
            overdraft: 0
        }

        this._changedSelect = this._changedSelect.bind(this);

    }

    _changedSelect(item, value) {
        this.data[item] = value;
        this.props.returnedData(this.data);
        this.data = {}
    }
    onInputChange = (key, value) => {
        this.setState({
            ...this.state,
            [key]: value,
        });
        this.props.onInputChange(key, value);
    }
    render(){
        const { translate } = this.props;
        return(

            <div className="slideMinH">
                {/* <div className="topTitleLine" /> */}
                <h2>

                    {
                        this.props.coApplicantStatus ?
                            translate("slide7.title.co")
                        :
                            translate("slide7.title.main")
                    }
                    
                </h2>

                <h3 id={"subtiutle-instalment"}>
                    {translate("slide7.subtitle.instalment")}
                </h3>

                <div className="row selectInputBox">
                    <div className="col-sm-6">
                        <div className="SelectTopTitle">
                            <span>{translate("slide7.placeHolder.home")}</span>
                        </div>
                        <div className="row">
                            <div className="FixedBoxWraper">
                                <FixedBox />
                            </div>
                            <div className="col-sm-9 col-xs-9 InputTextWithFixedBox">
                                <InputText
                                    id='homeLoan'
                                    value ={this.state.homeLoan}
                                    onChange = {(key, value)=> this.onInputChange(key, value)}
                                    placeholder={translate("slide7.placeHolder.home")}
                                    autoFocus={true}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="SelectTopTitle">
                            <span>{translate("slide7.placeHolder.car")}</span>
                        </div>
                        <div className="row">
                            <div className="FixedBoxWraper">
                                <FixedBox />
                            </div>
                            <div className="col-sm-9 col-xs-9 InputTextWithFixedBox">
                                <InputText
                                    id='carLoan'
                                    value ={this.state.carLoan}
                                    onChange = {(key, value)=> this.onInputChange(key, value)}
                                    placeholder={translate("slide7.placeHolder.car")}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row selectInputBox">
                    <div className="col-sm-6">
                        <div className="SelectTopTitle">
                            <span>{translate("slide7.placeHolder.personal")}</span>
                        </div>
                        <div className="row">
                            <div className="FixedBoxWraper">
                                <FixedBox />
                            </div>
                            <div className="col-sm-9 col-xs-9 InputTextWithFixedBox">
                                <InputText
                                    id='personalLoan'
                                    value ={this.state.personalLoan}
                                    onChange = {(key, value)=> this.onInputChange(key, value)}
                                    placeholder={translate("slide7.placeHolder.personal")}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="SelectTopTitle">
                            <span>{translate("slide7.placeHolder.PTPTN")}</span>
                        </div>
                        <div className="row">
                            <div className="FixedBoxWraper">
                                <FixedBox />
                            </div>
                            <div className="col-sm-9 col-xs-9 InputTextWithFixedBox">
                                <InputText
                                    id='PTPTN'
                                    value ={this.state.PTPTN}
                                    onChange = {(key, value)=> this.onInputChange(key, value)}
                                    placeholder={translate("slide7.placeHolder.PTPTN")}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row selectInputBox">
                    <div className="col-sm-6">
                        <div className="SelectTopTitle">
                            <span>{translate("slide7.placeHolder.other")}</span>
                        </div>
                        <div className="row">
                            <div className="FixedBoxWraper">
                                <FixedBox />
                            </div>
                            <div className="col-sm-9 col-xs-9 InputTextWithFixedBox">
                                <InputText
                                    id='otherLoans'
                                    value ={this.state.otherLoans}
                                    onChange = {(key, value)=> this.onInputChange(key, value)}
                                    placeholder={translate("slide7.placeHolder.other")}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">

                    </div>
                </div>

                <h3 id={"subtitle-outstanding"}>
                    {translate("slide7.subtitle.outstanding")}
                    <Tooltip
                        parent="Tooltipmuchincometax"
                        title={translate("slide7.tooltip.title")}
                        text={translate("slide7.tooltip.text")}
                    />
                </h3>
                <div className="row selectInputBox">
                    <div className="col-sm-6">
                        <div className="SelectTopTitle">
                            <span>{translate("slide7.placeHolder.creditCard")}</span>
                        </div>
                        <div className="row">
                            <div className="FixedBoxWraper">
                                <FixedBox />
                            </div>
                            <div className="col-sm-9 col-xs-9 InputTextWithFixedBox">
                                <InputText
                                    id='creditCard'
                                    value ={this.state.creditCard}
                                    onChange = {(key, value)=> this.onInputChange(key, value)}
                                    placeholder={translate("slide7.placeHolder.creditCard")}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="SelectTopTitle">
                            <span>{translate("slide7.placeHolder.overdraft")}</span>
                        </div>
                        <div className="row">
                            <div className="FixedBoxWraper">
                                <FixedBox />
                            </div>
                            <div className="col-sm-9 col-xs-9 InputTextWithFixedBox">
                                <InputText
                                    id='overdraft'
                                    value ={this.state.overdraft}
                                    onChange = {(key, value)=> this.onInputChange(key, value)}
                                    placeholder={translate("slide7.placeHolder.overdraft")}
                                />
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        )
     }
}


/* Export Component =============================== */
export default Slide7;