import { actionTypes } from './actionTypes';
import { AppAPI } from '../../lib';
import service from '../../service';
import Util from '../../lib/Util';
import newJWT from '../../lib/jwt';
import env from '../../.env.js';

export const clickHandler = (data) => {
    return {
        type : actionTypes.GET_DATA,
        payload: data
    }
}

export const updateUserData = (data) => {
    // debugger;
    return {
        type : actionTypes.UPDATE_DATA,
        payload: data
    }
}

export const updateCoApplicantData = (id, data) => {
    return {
        type : actionTypes.UPDATE_COAPPLICANT_DATA,
        payloadId: id,
        payload: data
    }
}

export const addNewApplicant = (data) => {
    return {
        type : actionTypes.ADD_NEW_APPLICANT,
        payload: data
    }
}

export function eligibilityCheckAction() {
    return (dispatch, getState) => new Promise(async (resolve, reject) => {

        const myLoanData = getState().core.loanData;
        const mycoApplicant = getState().core.coApplicant;
        const apiKey = getState().core.auth.apiKey;
        const code = getState().core.auth.code;
        const extraData = getState().core.extraData;
        // const data = JSON.stringify({
        let data = {
            "product_code": "MLF", // --------------------------->
            "language": Util.getQueryString(window.location.href, 'language') || "en",
            "applicant": {
                "client_ip": extraData.client_ip,
  	            "referrer": document.referrer,
                "DOB": '',
                "nationality": "Malaysian", // --------------------------->
                "employment_type": myLoanData.userType === 'Self' ? 'Self-Employed' : 'Employed',
                "additional_data": {
                    "name": myLoanData.name || '',
                    "gender": myLoanData.gender || '',
                    "age": myLoanData.age || '',
                    "phone_number": `+${myLoanData.mobileNumber}`,
                    "email": myLoanData.email,
                },
                "incomes": {
                    "gross_salary": {
                        "value": Number(myLoanData.basicSalary) || 0,
                        "currency_code": myLoanData.basicSalaryCurrency || "MYR"
                    },
                    "gross_fixed_allowance": {
                        "value": Number(myLoanData.fixedAllowance) || 0,
                        "currency_code": myLoanData.fixedAllowanceCurrency || "MYR"
                    },
                    "commission": {
                        "value": Number(myLoanData.commission) || 0,
                        "currency_code": myLoanData.commissionCurrency || "MYR"
                    },
                    "rental_income": {
                        "value": Number(myLoanData.rentalIncome) || 0,
                        "currency_code": myLoanData.rentalIncomeCurrency || "MYR"
                    },
                    "annual_fees": { // --------------------------->
                        "value": Number(myLoanData.directorsFees) || 0,
                        "currency_code": myLoanData.directorsFeesCurrency || "MYR"
                    },
                    "business_turnover": { // --------------------------->
                        "value": 0,
                        "currency_code": "MYR"
                    },
                    "contractual_bonus": {
                        "value": Number(myLoanData.contractualBonus) || 0,
                        "currency_code": myLoanData.contractualBonusCurrency || "MYR"
                    },
                    "performance_bonus": {
                        "value": Number(myLoanData.performanceBonus) || 0,
                        "currency_code": myLoanData.performanceBonusCurrency || "MYR"
                    },
                    "overtime": {
                        "value": Number(myLoanData.overtime) || 0,
                        "currency_code": myLoanData.overtimeCurrency || "MYR"
                    },
                    "asb_income": {
                        "value": Number(myLoanData.ASBDividends) || 0,
                        "currency_code": myLoanData.ASBDividendsCurrency || "MYR"
                    },
                    "b_form_revenue": {
                        "value": Number(myLoanData.totalIncome) || 0,
                        "currency_code": myLoanData.totalIncomeCurrency || "MYR"
                    },
                    "interest_deposit_income": {
                        "value": Number(myLoanData.interestDeposits) || 0,
                        "currency_code": myLoanData.interestDepositsCurrency || "MYR"
                    }
                },
                "commitments": {
                    "mortgage_loan": [
                        {
                            "monthly_instalment": Number(myLoanData.homeLoan) || 0,
                            "currency_code": "MYR",
                            "joint_name": false
                        }
                    ],
                    "personal_loan": [
                        {
                            "monthly_instalment": Number(myLoanData.personalLoan) || 0,
                            "currency_code": "MYR",
                            "joint_name": false
                        }
                    ],
                    "other_loan": [
                        {
                            "monthly_instalment": Number(myLoanData.otherLoans) || 0,
                            "currency_code": "MYR",
                            "joint_name": false
                        },
                        {
                            "monthly_instalment": Number(myLoanData.PTPTN) || 0,
                            "currency_code": "MYR",
                            "joint_name": false
                        }
                    ],
                    "hire_purchase": [
                        {
                            "monthly_instalment": Number(myLoanData.carLoan) || 0,
                            "currency_code": "MYR",
                            "joint_name": false
                        }
                    ],
                    "credit_card": [
                        {
                            "outstanding": Number(myLoanData.creditCard) || 0,
                            "currency_code": "MYR"
                        }
                    ],
                    "overdraft": [
                        {
                            "outstanding": Number(myLoanData.overdraft) || 0,
                            "currency_code": "MYR"
                        }
                    ]
                },
                "statutory_deductions": {
                    "pcb": {
                        "value": Number(myLoanData.incomeTax) || 0,
                        "currency_code": myLoanData.incomeTaxCurrency || "MYR",
                    }
                }
            },
            "product_details": { // --------------------------->
                "loan_purpose": "Purchase",
                "margin_of_finance": 90,
                "purchase_price": 0,
                "tenure": 0
            }
        };
        // });

        let extra = [];
        let searchData = window.location.search;
        if (searchData) {
            searchData = searchData.replace('?', '');
            const queryParam = searchData.split('&');
            let fiRes = {};
            queryParam.map((item) => {
                const q = item.split('=');
                fiRes[q[0]] = q[1];
            })
            extra = {
                "extra_data": fiRes
            }
        }

        let coAppl = [];
        if (mycoApplicant.length > 0) {
            let coApp = [];
            mycoApplicant.map((item) => {
                const data = {
                    "DOB": '',
                    "nationality": "Malaysian", // --------------------------->
                    "employment_type": item.userType === 'Self' ? 'Self-Employed' : 'Employed',
                    "additional_data": {
                        "name": item.name || '',
                        "gender": item.gender || '',
                        "age": item.age || '',
                        "phone_number": `+${item.mobileNumber}`,
                        "email": item.email,
                    },
                    "incomes": {
                        "gross_salary": {
                            "value": Number(item.basicSalary) || 0,
                            "currency_code": item.basicSalaryCurrency || "MYR"
                        },
                        "gross_fixed_allowance": {
                            "value": Number(item.fixedAllowance) || 0,
                            "currency_code": item.fixedAllowanceCurrency || "MYR"
                        },
                        "commission": {
                            "value": Number(item.commission) || 0,
                            "currency_code": item.commissionCurrency || "MYR"
                        },
                        "rental_income": {
                            "value": Number(item.rentalIncome) || 0,
                            "currency_code": item.rentalIncomeCurrency || "MYR"
                        },
                        "annual_fees": {
                            "value": Number(item.directorsFees) || 0,
                            "currency_code": item.directorsFeesCurrency || "MYR"
                        },
                        "business_turnover": { // --------------------------->
                            "value": 0,
                            "currency_code": "MYR"
                        },
                        "contractual_bonus": {
                            "value": Number(item.contractualBonus) || 0,
                            "currency_code": item.contractualBonusCurrency || "MYR"
                        },
                        "performance_bonus": {
                            "value": Number(item.performanceBonus) || 0,
                            "currency_code": item.performanceBonusCurrency || "MYR"
                        },
                        "overtime": {
                            "value": Number(item.overtime) || 0,
                            "currency_code": item.overtimeCurrency || "MYR"
                        },
                        "asb_income": {
                            "value": Number(item.ASBDividends) || 0,
                            "currency_code": item.ASBDividendsCurrency || "MYR"
                        },
                        "b_form_revenue": {
                            "value": Number(item.totalIncome) || 0,
                            "currency_code": item.totalIncomeCurrency || "MYR"
                        },
                        "interest_deposit_income": {
                            "value": Number(item.interestDeposits) || 0,
                            "currency_code": item.interestDepositsCurrency || "MYR"
                        }
                    },
                    "commitments": {
                        "mortgage_loan": [
                            {
                                "monthly_instalment": Number(item.homeLoan) || 0,
                                "currency_code": "MYR",
                                "joint_name": false
                            }
                        ],
                        "personal_loan": [
                            {
                                "monthly_instalment": Number(item.personalLoan) || 0,
                                "currency_code": "MYR",
                                "joint_name": false
                            }
                        ],
                        "other_loan": [
                          {
                              "monthly_instalment": Number(item.otherLoans) || 0,
                              "currency_code": "MYR",
                              "joint_name": false
                          },
                          {
                              "monthly_instalment": Number(item.PTPTN) || 0,
                              "currency_code": "MYR",
                              "joint_name": false
                          }
                        ],
                        "hire_purchase": [
                            {
                                "monthly_instalment": Number(item.carLoan) || 0,
                                "currency_code": "MYR",
                                "joint_name": false
                            }
                        ],
                        "credit_card": [
                            {
                                "outstanding": Number(item.creditCard) || 0,
                                "currency_code": "MYR"
                            }
                        ],
                        "overdraft": [
                            {
                                "outstanding": Number(item.overdraft) || 0,
                                "currency_code": "MYR"
                            }
                        ]
                    },
                    "statutory_deductions": {
                        "pcb": {
                            "value": Number(item.incomeTax) || 0,
                            "currency_code": item.incomeTaxCurrency || "MYR",
                        }
                    }
                };
                if (item.email) {
                    coApp.push(data);
                }
            });

            if (coApp.length) {
                coAppl = {
                    "co_applicants": coApp
                }
            }
        }

        // const final = {
        //     ...data,
        //     ...coAppl
        // }

        const myData = JSON.stringify({
            ...data,
            ...coAppl,
            ...extra
        });

        const urlData = Util.getDataFromUrl(window.location);
        const params = {
            code: code,
            hostname: urlData.hostname
        }

        const header = {
            "alg": "HS512",
            "typ": "JWT"
        };
        const payload = {
            "api_key": apiKey,
            "code": code,
            "exp": new Date().getTime() + 60000
        };
        const secretKey = env.secret_key;
        const jwtToken = newJWT(header, payload, secretKey);

        AppAPI.eligibilityCheck(params, myData, jwtToken)
            .then((res) => {

                return resolve(res);
            })
            .catch((err) => {
                return reject(err);
            });
    });
}


export function eligibilityEmailAction(id, emailData) {
    return (dispatch, getState) => new Promise(async (resolve, reject) => {

        const data = JSON.stringify({
            "id": id,
            "phone_number": emailData.mobileNumber,
            "email": emailData.email,
        });

        AppAPI.eligibilityEmail('', data)
            .then((res) => {

                return resolve(res);
            })
            .catch((err) => {
                return reject(err);
            });
    });
}

export function getIPAction() {
    return (dispatch, getState) => new Promise(async (resolve, reject) => {

        AppAPI.getIP()
            .then((res) => {
                dispatch({type: actionTypes.EXTRA_DATA,
                    payload: {
                        client_ip: res.client_ip,
                        referrer: res.referrer
                }});
                return resolve(res);
            })
            .catch((err) => {
                return reject(err);
            });
    });
}

export const resetStoreAction = () => {
    return {
        type : actionTypes.RESET
    }
}

export const resetLoanData = () => ({type: actionTypes.RESET_LOAN_DATA})
export const isApiKeyValid = (hostname, code, token) => {
    return async (dispatch) => {
        dispatch({type: actionTypes.AUTH_LOADING});
        try{
            const response = await service.auth.verifyUser(hostname, code, token);
            const isVerified = response.verified;
            dispatch({type: actionTypes.AUTH_GET_VERIFICATION,
                payload: {
                    isVerified: isVerified,
                    apiKey: isVerified ? response.api_key : '',
                    code: isVerified ? response.code : '',
                    debugger: response.debugger || false
                }});
        }catch(e){
            console.error(e.message);
            dispatch({type: actionTypes.AUTH_FAILED});
        }
    }
}
export const unauthorizedRequest = () => ({type: actionTypes.AUTH_GET_VERIFICATION, payload: {isVerified: false, apiKey: '', debugger: false}})
