import React from 'react';
import PropTypes from 'prop-types';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Util from '../../../lib/Util';
import {clientTheme} from '../../../theme';

const client = Util.clientTheme();

class Slide3 extends React.Component {

    constructor(props) {
        super(props);

        this.basicSalaryRef = React.createRef();
        const {dataInStore} = this.props;
        this.state = {
            value: dataInStore.incomeType || ''
        }

        this.radio = [
            {
                id: 0,
                label: 'Basic Salary'
            },
            {
                id: 1,
                label: 'Basic Salary + Variable Income / Commission'
            },
            {
                id: 2,
                label: 'Pure Commission'
            }
        ]

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.focusBasicSalary();
    }

    handleChange(event) {
        this.setState({value: event.target.value})

        const data = {
            incomeType: event.target.value
        }
        this.props.selectedType(data);
    };

    handleKeyDown = (id, e) => {
        if (e.key === 'Enter') {
            this.handleChange({target: {value: this.radio[id].label}});
        }
    }
    focusBasicSalary = () => {
        this.basicSalaryRef.current.focus();
    }
    getRadioStyle = () => {
        if (client === "uems") {
            return {marginRight: 10}
        }
        return undefined;
    }

    render() {
        const {translate} = this.props;
        const radioStyle=this.getRadioStyle();
        return (
            <div>
                {/* <div className="topTitleLine" /> */}
                <h2>
                    {
                        this.props.coApplicantStatus ?
                            translate("slide3.title.co")
                            :
                            translate("slide3.title.main")
                    }
                </h2>

                <RadioGroup value={this.state.value} onChange={this.handleChange} className="slide3Radio">
                    <FormControlLabel
                        value={this.radio[0].label}
                        control={<Radio
                            style={radioStyle}
                            color="default"/>}
                        label={translate("slide3.type.basic")}
                        ref={this.basicSalaryRef}
                        tabIndex={-1}
                        onKeyDown={(e) => this.handleKeyDown(this.radio[0].id, e)}
                    />
                    <FormControlLabel
                        tabIndex={-1}
                        onKeyDown={(e) => this.handleKeyDown(this.radio[1].id, e)}
                        value={this.radio[1].label}
                        control={<Radio
                            style={radioStyle}
                            color="default"/>}
                        label={translate("slide3.type.variable")}
                    />
                    <FormControlLabel
                        tabIndex={-1}
                        onKeyDown={(e) => this.handleKeyDown(this.radio[2].id, e)}
                        value={this.radio[2].label}
                        control={<Radio
                            style={radioStyle}
                            color="default"/>}
                        label={translate("slide3.type.commission")}
                    />
                </RadioGroup>

            </div>

        )
    }
}


/* Export Component =============================== */
export default Slide3;