import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from '../translations/en';
import cn from '../translations/cn';
// import ml from '../translations/ml';

const resources = {
  en: { translation: en },
  cn: { translation: cn },
  // ml: { translation: ml }
};


i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en",

    keySeparator: '.', // we use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;