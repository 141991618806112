import { connect } from 'react-redux';


// Actions
// import { clickHandler } from '@redux/core/actions';
import { updateUserData, 
        eligibilityCheckAction, 
        addNewApplicant, 
        updateCoApplicantData, 
        eligibilityEmailAction, 
        resetLoanData,
        isApiKeyValid,
        unauthorizedRequest,
        getIPAction
} from '../../redux/core/actions';

// The component we're mapping to
import view from './view';

// What data from the store shall we send to the component?
const mapStateToProps = state => ({
    loanData: state.core.loanData,
    coApplicant: state.core.coApplicant,
    selectedId: state.core.selectedId,
    auth: state.core.auth,
});

// Any actions to map to the component?
const mapDispatchToProps = {
    updateUserData,
    eligibilityCheckAction,
    addNewApplicant,
    updateCoApplicantData,
    eligibilityEmailAction,
    resetLoanData,
    isApiKeyValid,
    unauthorizedRequest,
    getIPAction
};

export default connect(mapStateToProps, mapDispatchToProps)(view);
