import React from 'react';

import './style.scss';
class Header extends React.Component{
    
    constructor(props) {
        super(props);

        this.state = {
        }

    }

    render(){
        const { leftLogoName, rightLogoName, leftLogoLink, rightLogoLink } = this.props;
        return(
            <nav>
                <div className="container nav-box">
                    <div className="nav-left">
                        <a href={leftLogoLink} >
                        {
                            leftLogoName &&
                            <img src={require(`../../assets/images/${leftLogoName}`)} alt="logo" />
                        }
                        </a>
                    </div>
                    <div className="nav-right d-flex justify-content-end">
                        <a href={rightLogoLink} >
                        {
                            rightLogoName &&
                                <img src={require(`../../assets/images/${rightLogoName}`)} alt="logo" />
                        }
                        </a>
                    </div>
                </div>
            </nav>

        )
     }
}

/* Export Component =============================== */
export default Header;