import React, {Component} from 'react';
// import './theme/styles.scss';
import {appStore} from './redux/store';
import {Provider} from 'react-redux';
import {Helmet} from "react-helmet";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'react-circular-progressbar/dist/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-phone-input-2/dist/style.css';
import 'typeface-roboto';

import './manifest';

import {clientTheme} from './theme';
import {ThemeProvider} from 'styled-components';

import Util from './lib/Util';

import './theme/main.scss';

import Home from './containers/Home';
import {GlobalStyle} from './containers/Home/styles.js';

const client = Util.clientTheme();

const theme = {
    ...clientTheme[client]
}


class App extends Component {
    render() {
        return (
            <ThemeProvider theme={theme}>
                <Helmet>
                    <link id="favicon" rel="icon" href={clientTheme[client].image.favIcon} type="image/x-icon"/>
                    <meta name="robots" content="noindex"/>
                    <title>{clientTheme[client].title}</title>
                </Helmet>
                <Provider store={appStore}>
                    <Home
                        themeName={client}
                    />
                </Provider>
                <GlobalStyle/>
            </ThemeProvider>
        );
    }
}

export default App;