import { actionTypes } from './actionTypes';
// import i18n from'../../lib/i18n';

const initialState = {
    loanData: {},
    coApplicant: [],
    selectedId: 0, /**means it is main */
    auth:{
        apiKey: '',
        isLoading: true,
        isVerified: false,
        isFailed: false,
        debugger: false
    },
    extraData: {}
}

export default function core(state = initialState, action) {
    switch (action.type) {

        case actionTypes.UPDATE_DATA:
            if (!action.payload) return state;

            return {
                ...state,
                selectedId: 0,
                loanData: {
                    ...state.loanData,
                    ...action.payload,
                },
            };
            // let loanData = {
            //     ...state.loanData
            // };
            // Object.keys(action.payload).forEach(function(key) {
            //     loanData[key] = action.payload[key];
            // });
            
            // return Object.assign({}, state, {
            //     loanData
            // });


        case actionTypes.ADD_NEW_APPLICANT:
            if (!action.payload) return state;    
        
            return {
                ...state,
                selectedId: action.payload,
                coApplicant: [
                    ...state.coApplicant,
                    {
                        id: action.payload
                    }
                ]
            };


        case actionTypes.UPDATE_COAPPLICANT_DATA:
            if (!action.payload || !action.payloadId) return state;

            const newCoApplicant = JSON.parse(JSON.stringify(state.coApplicant));
            const index = state.coApplicant.map((itm) => { return itm.id; }).indexOf(action.payloadId);
            if (index > -1) {
                Object.keys(action.payload).forEach(function(key) {
                    newCoApplicant[index][key]= action.payload[key];
                });
            }
            return {
                ...state,
                selectedId: action.payloadId,
                coApplicant: [
                    ...newCoApplicant
                ]
            };

        case "EXTRA_DATA":
            return {
                ...state,
                extraData: {
                    ...state.extraData,
                    ...action.payload
                },
            };

        case "AUTH_LOADING":
            return {
                ...state,
                auth:{
                    ...state.auth,
                    isLoading: true,
                    isFailed: false,
                    isVerified: false,
                    debugger: false
                },
            };
        case "AUTH_GET_VERIFICATION":
            return {
                ...state,
                auth: {
                    ...state.auth,
                    isLoading: false,
                    isFailed: false,
                    isVerified: action.payload.isVerified,
                    apiKey: action.payload.apiKey,
                    code: action.payload.code,
                    debugger: action.payload.debugger,
                    client_ip: action.client_ip,
                    referrer: action.referrer
                },
            };
        case "AUTH_FAILED":
            return {
                ...state,
                auth: {
                    ...state.auth,
                    isLoading: false,
                    isFailed: true,
                    isVerified: false,
                },
            };
    case "RESET_LOAN_DATA": 
            return {
                ...state,
                loanData: {},
                coApplicant: [],
                selectedId: 0,
            }
        case "RESET":
            return initialState;

        default:
            return {...state}
        }
    }