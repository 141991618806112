import styled from "styled-components";


export const FormControl = styled.select`
  border: 0;
  border-bottom: 1px solid ${({theme}) => theme.color.tertiary};
  border-radius: 0;
  height: 38px;
  -webkit-appearance: none;
  appearance: none;
  -moz-appearance: none;

  cursor: pointer;
  background: none;
  position: relative;
  z-index: 2;

  &:focus {
    box-shadow: none !important;
    border-bottom: 3px solid ${({theme}) => theme.color.tertiary};
    background: none;
  }

  &:active {
    box-shadow: none !important;
    border-bottom: 3px solid ${({theme}) => theme.color.tertiary};
    background: none;
  }

  &:visited {
    box-shadow: none !important;
    border-bottom: 1px solid ${({theme}) => theme.color.tertiary};
    background: none;
  }
`;

export const SelectDown = styled.i`
  position: absolute;
  right: 5px;
  top: 10px;
  color: ${({theme}) => theme.color.tertiary};
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  z-index: 1;
  ${({theme}) => {
    if (theme.themeName && theme.themeName === "uems") {
      return `
            font-size: 12px;
            color: ${theme.color.black_dark};
            z-index: 2;
            right:12px;
            top:12px;
            `
    }
    return ``
  }}
`;
