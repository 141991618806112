import util from '../lib/Util';
import api from '../constants/api';

const verifyUser = async (hostname, code, token) => {
    let options = {
      method: 'GET',
      headers: {'Content-Type': 'application/json', 'Authorization-Token': token}
    };
    const url = api.hostname + api.endpoints.find((endpoint) => endpoint.key === "authorization").url;
    const endUrl = url + `?code=${code}&hostname=${hostname}`;
    let response = await util.handleRequest(endUrl, options);
    let handledResponse = await util.handleResponse(response);
    return handledResponse;
}

export {
    verifyUser,
}
