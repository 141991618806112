import React from 'react';
import PropTypes from 'prop-types';

import Select from '../../../components/Select/index';
import InputText from '../../../components/InputText/index';
import Tooltip from '../../../components/Tooltip/index';
import FixedBox from '../../../components/FixedBox/index';

class Slide6 extends React.Component{
    
    constructor(props) {
        super(props);

        const {dataInStore} = this.props;
        this.state = {
            incomeTax: dataInStore.incomeTax || '',
            incomeTaxCurrency: dataInStore.incomeTaxCurrency || 'MYR',
        }

        this.currency = [
            { label: 'AED' },
            { label: 'AUD' },
            { label: 'BND' },
            { label: 'EUR' },
            { label: 'GBP' },
            { label: 'HKD' },
            { label: 'IDR' },
            { label: 'INR' },
            { label: 'JPY' },
            { label: 'MYR' },
            { label: 'NZD' },
            { label: 'QAR' },
            { label: 'RMB' },
            { label: 'SGD' },
            { label: 'THB' },
            { label: 'USD' },
        ];

        this.data = {
            incomeTaxCurrency: 'MYR',
            incomeTax: 0
        }

        this._changedSelect = this._changedSelect.bind(this);

    }

    _changedSelect(item, value) {
        this.data[item] = value;
        this.props.returnedData(this.data);
        this.data = {}
    }

    onInputChange = (key, value) => {
        this.setState({
            ...this.state,
            [key]: value,
        });
        this.props.onInputChange(key, value);
    }
    render(){
        let { translate } = this.props;
        return(

            <div>
                {/* <div className="topTitleLine" /> */}
                <h2>
                    {
                        this.props.coApplicantStatus ?
                            translate("slide6.title.co")
                        :
                            translate("slide6.title.main")
                    }
                     <Tooltip
                        parent="Tooltipmuchincometax"
                        text={translate("slide6.tooltip")}
                    />
                </h2>

                <div className="row selectInputBox">
                <div className="col-sm-8">
                        <div className="SelectTopTitle">
                            <span>{translate("slide6.placeHolder.scheduled")}</span>
                        </div>
                        <div className="row remove-mb">
                        <div className="col-sm-4 col-xs-4 paddingRight">
                                <FixedBox />
                        </div>
                         <div className="col-sm-8 col-xs-8 InputTextWithFixedBox">
                             <InputText
                                id='incomeTax'
                                value ={this.state.incomeTax}
                                onChange = {(key, value)=> this.onInputChange(key, value)}
                                 placeholder={translate("slide6.placeHolder.scheduled")}
                                 autoFocus={true}
                             />
                         </div>
                        </div>
                </div>
            </div>
            </div>

        )
     }
}


/* Export Component =============================== */
export default Slide6;