export const actionTypes = {
    GET_DATA: 'GET_DATA',
    UPDATE_DATA: 'UPDATE_DATA',
    ADD_NEW_APPLICANT: 'ADD_NEW_APPLICANT',
    UPDATE_COAPPLICANT_DATA: 'UPDATE_COAPPLICANT_DATA',
    RESET: 'RESET',
    RESET_LOAN_DATA: 'RESET_LOAN_DATA',
    
    AUTH_LOADING: 'AUTH_LOADING',
    AUTH_FAILED: 'AUTH_FAILED',
    AUTH_GET_VERIFICATION: 'AUTH_GET_VERIFICATION', 
    EXTRA_DATA: 'EXTRA_DATA', 
};