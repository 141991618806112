/**
 * App Labels
 *
 * */

export default {
    error: {
        networkError: 'Network Error',
        timeOut: 'Timeout Error',
        default: 'Unknown Error'
    }
};
