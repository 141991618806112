import React from 'react';
import PropTypes from 'prop-types';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Modal from 'react-bootstrap/Modal';

import {ResultWidget, Rate, MeterDisp, MeterGood, PriceDisp, RsltIstr, AffordabilityForm, SmallAmount} from "../styled";

import Select from '../../../components/Select/index';
import InputText from '../../../components/InputText/index';
import Util from '../../../lib/Util';
import Tooltip from '../../../components/Tooltip/index';
import RSelect from 'react-select'
import {clientTheme} from '../../../theme';

const client = Util.clientTheme();

class Slide7 extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showModal: false
        }

        this._nextSlide = this._nextSlide.bind(this);
        this._statColor = this._statColor.bind(this);
        this._mapStatusT = this._mapStatusT.bind(this);
    }

    _nextSlide() {
        this.props.returnedData(true);
    }

    _statColor(item) {
        let cName = '';
        if (item === 'Excellent') {
            cName = 'ratingStatusExcellent';
        } else if (item === 'Good') {
            cName = 'ratingStatusGood';
        } else if (item === 'Fair') {
            cName = 'ratingStatusFair';
        } else if (item === 'Poor') {
            cName = 'ratingStatusPoor';
        } else if (item === 'Not Eligible') {
            cName = 'ratingStatusNotEligibile';
        }
        return cName;
    }

    _mapStatusT(item) {
        let {translate} = this.props;
        let cName = '';
        if (item === 'Excellent') {
            cName = translate("slide10.tooltip.excellent");
        } else if (item === 'Good') {
            cName = translate("slide10.tooltip.good");
        } else if (item === 'Fair') {
            cName = translate("slide10.tooltip.fair");
        } else if (item === 'Poor') {
            cName = translate("slide10.tooltip.poor");
        } else if (item === 'Not Eligible') {
            cName = translate("slide10.tooltip.notEligible");
        }
        return cName;
    }

    render() {
        let {eligibilityCheckData, translate} = this.props;
        let gra = 0;
        let meterLeft = 0;
        if (eligibilityCheckData && eligibilityCheckData.dsr_avg) {
            gra = Math.round(Util.removeCharWithoutDot(eligibilityCheckData.dsr_avg)) || 0;
            if (gra > 100) {
                gra = 100;
            }
        }
        meterLeft = (80 * gra) / 100;
        gra = 100 - gra;

        return (

            <div>
                {/* <div className="topTitleLine" /> */}
                <h2>
                    {translate("slide10.title.main")}
                </h2>

                {
                    eligibilityCheckData &&
                    <div className="row">
                        <div className="col-sm-5 resultResponsive">
                            <ResultWidget className="result-widget">
                                <Rate className="rate">{eligibilityCheckData.dsr_avg}</Rate>

                                <MeterDisp className="meter-disp">
                                        <span className="gradient">
                                            <small
                                                className={this._statColor(eligibilityCheckData.dsr_status_avg)}></small>
                                            <i className="gradientCover" style={{width: `${gra}%`}}></i>
                                        </span>
                                </MeterDisp>
                                <div className="meter-good-box">
                                    <MeterGood className="meter-good"
                                               style={{left: `${meterLeft}%`}}>{this._mapStatusT(eligibilityCheckData.dsr_status_avg)}</MeterGood>
                                </div>
                                <p>
                                    {translate("slide10.placeHolder.debt")}
                                    <Tooltip
                                        parent="TooltDebtServicing"
                                        text={translate("slide10.tooltip.debt")}
                                    />
                                </p>
                            </ResultWidget>
                        </div>
                        <div className="col-sm-7 resultResponsive">
                            <PriceDisp className="price-disp">
                                <p className="maxText">
                                    {translate("slide10.placeHolder.affordability")}
                                    <Tooltip
                                        parent="TooltMaxAffordability"
                                        text={translate("slide10.tooltip.affordability")}
                                    />
                                </p>
                                <div className="disp">
                                    <span
                                        id={"rslt-b"}>MYR</span><SmallAmount>{eligibilityCheckData.max_affordability_avg}</SmallAmount>
                                    <RsltIstr className="rslt-istr">{translate("slide10.placeHolder.instalment")}
                                        <b>MYR {eligibilityCheckData.installment}</b>, {translate("slide10.placeHolder.tenure")}
                                        <b>{Util.removeCharWithoutDot(eligibilityCheckData.tenur)} {translate("slide10.placeHolder.year")}</b>, {translate("slide10.placeHolder.interest")}
                                        <b>{eligibilityCheckData.interest}</b> </RsltIstr>
                                </div>
                            </PriceDisp>
                        </div>
                    </div>
                }

                <div className="button-section remove-mb row">
                    <div className="col-sm-6">
                        <i className="reportUnderline" onClick={() => this.setState({showModal: true})}>
                            {translate("slide10.placeHolder.report")}
                        </i>
                    </div>
                    <div className="col-sm-6">
                        <button
                            className="slide-begin btn btn-submit btn-block"
                            autoFocus={true}
                            onClick={() => this._nextSlide()}
                        >
                            {translate("slide10.placeHolder.recalculate")} <i className="fa fa-long-arrow-right"></i>
                        </button>
                    </div>
                </div>


                <div className="row">
                    <div className="col-md-12">
                        <AffordabilityForm className="affordability-form">
                            <h5 className="title-04">
                                {translate("slide10.placeHolder.allBank")}
                            </h5>
                            <div className={`table-responsive ${client === "uems" ? "tableScrollUems" : "tableScroll"}`}>
                                <table className="table table-striped" cellSpacing="0" width="100%">
                                    <thead>
                                    <tr className="tableTileBox">
                                        <th>
                                            {translate("slide10.placeHolder.lender")}
                                        </th>
                                        <th>
                                            {translate("slide10.placeHolder.debt")}
                                        </th>
                                        <th>
                                            {translate("slide10.placeHolder.maxLoan")}
                                        </th>
                                        <th>
                                            {translate("slide10.placeHolder.instalment")}
                                        </th>
                                        <th>
                                            {translate("slide10.placeHolder.rating")}
                                            <Tooltip
                                                parent="TooltTableRating"
                                                table
                                                translate={translate}
                                            />
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        (eligibilityCheckData && eligibilityCheckData.banks) &&
                                        eligibilityCheckData.banks.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{item.name}</td>
                                                    <td>{item.dsr}</td>
                                                    <td>{item.max_affordability}</td>
                                                    <td>MYR {item.installment}</td>
                                                    <td className={"tableRatindStatus " + this._statColor(item.status)}>
                                                        {this._mapStatusT(item.status)}
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </AffordabilityForm>
                    </div>
                </div>

                <Modal
                    show={this.state.showModal}
                    onHide={() => this.setState({showModal: false})}
                    dialogClassName="modal-90w"
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    id="myModal"
                >
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <h2>
                            {translate("slide10.modal.title")}
                        </h2>
                        <p>
                            {translate("slide10.modal.subTitle")}
                        </p>
                        <ul>
                            <li>
                                {translate("slide10.modal.line1")}{eligibilityCheckData.interest}{translate("slide10.modal.dot")}
                            </li>
                            <li>
                                {translate("slide10.modal.line2")}
                            </li>
                            <li>
                                {translate("slide10.modal.line3")}
                            </li>
                            <li>
                                {translate("slide10.modal.line4")}
                            </li>
                            <li>
                                {translate("slide10.modal.line5")}
                            </li>
                            <li>
                                {translate("slide10.modal.line6")}
                            </li>
                            <li>
                                {translate("slide10.modal.line7")}
                            </li>
                        </ul>
                    </Modal.Body>
                </Modal>

            </div>

        )
    }
}


/* Export Component =============================== */
export default Slide7;