import spsetiaBgImg from '../assets/images/spsetia/slide1-background.jpg';
import tcbBgImg from '../assets/images/tcb/slide1-background.png';

import spsetiaFavIcon from '../assets/images/spsetia/favicon.ico';
import tcbFavIcon from '../assets/images/favicon.ico';

import defaultFavIcon from '../assets/images/favicon.ico';
import defaultBgImg from '../assets/images/default/slide1-background.png';

const clientTheme = {
    default: {
        color: {
            primary: '#21295b',
            primary2: '#21295b',
            secondary: '#74bad6',
            secondary2: '#74bad6',
            tertiary: '#74bad6',
            tertiary2: '#74bad6',
            black_dark: '#3e3e3e',
            black_light: '#585858',
            border: '#D4D4D4',
            gray_dark: '#929292',
            gray: '#EBEBEB',
            white: '#FFFFFF',
            red: '#F22E2E',
            message_color: '#585858',
            svg: 'none', //#000
            svgActive: 'none', //#fff
            slide1bg: 'rgba(33, 77, 91, 0.8)',
            bgColor: '#FFFFFF'
        },
        image: {
            slide1bgImage: defaultBgImg,
            favIcon: defaultFavIcon
        },
        font: {
            name: 'minionpro'
        },
        name: "Finology Mortgage Loan Calculator",
        title: "Finology Sdn Bhd Mortgage Loan Calculator",
        scriptUrl: ""
    },
    spsetia: {
        color: {
            primary: '#003893',
            primary2: '#E8112D',
            secondary: '#003893',
            secondary2: '#6D6E71',
            tertiary: '#000000',
            tertiary2: '#003893',
            black_dark: '#000000',
            black_light: '#000000',
            border: '#D4D4D4',
            gray_dark: '#6D6E71',
            gray: '#e0e4f2',
            white: '#FFFFFF',
            red: '#E8112D',
            message_color: '#E8112D',
            svg: 'invert(0%) sepia(5%) saturate(0%) hue-rotate(216deg) brightness(94%) contrast(101%)', //#000
            svgActive: 'invert(100%) sepia(39%) saturate(0%) hue-rotate(302deg) brightness(117%) contrast(97%)', //#fff
            slide1bg: 'rgba(0, 56, 147, 0.80)',
            bgColor: '#f1f4f8'
        },
        image: {
            slide1bgImage: spsetiaBgImg,
            favIcon: spsetiaFavIcon
        },
        font: {
            name: 'stonsans'
        },
        name: "S P Setia Berhad’s",
        title: "S P Setia Mortgage Loan Calculator",
        scriptUrl: ""
    },
    tcb: {
        color: {
            primary: '#004711',
            primary2: '#004711',
            secondary: '#719479',
            secondary2: '#719479',
            tertiary: '#CCCC99',
            tertiary2: '#CCCC99',
            black_dark: '#3e3e3e',
            black_light: '#585858',
            border: '#D4D4D4',
            gray_dark: '#929292',
            gray: '#EBEBEB',
            white: '#FFFFFF',
            red: '#F22E2E',
            message_color: '#585858',
            svg: 'none', //#000
            svgActive: 'none', //#fff
            slide1bg: 'rgba(0, 71, 17, 0.85)',
            bgColor: '#FFFFFF'
        },
        image: {
            slide1bgImage: tcbBgImg,
            favIcon: tcbFavIcon
        },
        font: {
            name: 'minionpro'
        },
        name: "Tropicana Corporation Berhad's",
        title: "Tropicana Mortgage Loan Calculator",
        scriptUrl: "https://tropicanacorp.com.my/candypixel/loan_plus.js"
    },
    uems: {
        color: {
            primary: '#4477B8',
            primary2: '#eb95b9',
            secondary: '#182C71',
            secondary2: '#182C71',
            tertiary: '#4477B8',
            tertiary2: '#4477B8',
            black_dark: '#3e3e3e',
            black_light: '#585858',
            border: '#D4D4D4',
            gray_dark: '#929292',
            gray: '#EBEBEB',
            white: '#FFFFFF',
            red: '#F22E2E',
            message_color: '#585858',
            svg: 'none', //#000
            svgActive: 'none', //#fff
            slide1bg: 'linear-gradient(180deg, rgba(29, 159, 149, 0.9) 0%, rgba(68, 119, 184, 0.9) 100%);',
            bgColor: '#FFFFFF',
            softBlue:"#F1F4F8"
        },
        image: {
            slide1bgImage: spsetiaBgImg,
            favIcon: tcbFavIcon
        },
        font: {
            name: 'bw-modelica'
        },
        themeName: "uems",
        name: "",
        title: "UEM Sunrise Mortgage Loan Calculator",
        scriptUrl: ""
    },
}

const clientsName = Object.keys(clientTheme);

export {clientTheme, clientsName};