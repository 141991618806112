import {createGlobalStyle} from 'styled-components';

const extendElementsForTheme = (theme) => {
    if (theme && theme.themeName) {
        if (theme.themeName === "uems") {
            return `
                button{
                    border-radius:30px!important;
                }
                #next{
                    margin-right:10px
                }
                input, optgroup, select, textarea, .FixedBoxWraper {
                    border-radius:30px!important;
                    background-color:#F6F6F6!important;
                    border-color:#F6F6F6!important;
                    font-weight:bold!important                                                        
                }            
                
                
                .FixedBoxWraper{
                    margin-right:0px               
                }
                .FixedBoxWraper div{
                    border-bottom:0px;                
                }         
                #previous{
                    border:0px!important
                }   
                .inputUp, .inputDown{
                    color:${theme.color.black_dark}!important;
                    font-size:12px;
                    margin:5px                   
                }
                }
                .selected-flag{
                    background-color: #F6F6F6!important;
                    margin-right: 9px;
                    border-radius: 26px!important;
                           
                }
                .flag-dropdown{
                    border-bottom:0px!important;
                }
                .edit-uems-btn{
                    border-color: ${theme.color.primary2}!important;
                    border-radius: 26px!important;
                    border-width:1px;
                    color: ${theme.color.primary2}!important;                                     
                }
                .sammarySubtitleLeft, 
                #TooltipYearlyAverage1 ,
                #TooltipMonthlyAverage1,
                #subtiutle-instalment,
                #subtitle-outstanding{
                    color: ${theme.color.primary}!important;
                }
                .affordability-form h5,.affordability-form div table thead{
                    background-color:${theme.color.softBlue};
                }
                .affordability-form h5{
                    border-bottom-color:#989898!important;
                }
                #rslt-b{
                   color: ${theme.color.primary}!important;
                }
                .tableScroll{
                    margin-top:-2px
                }                  
                .ageValidMsg{
                    font-size:12px                
                }
                .react-tel-input .selected-flag{
                    width: 60px;                    
                    padding: 0px 0 0 20px;
                }             
                #phone-form-control{
                    margin-left:30px;
                }
                .ratingStatusExcellent {
                    background: linear-gradient(90deg, #A1C728 0%, #BACE20 62.5%, #C9DB59 98.44%);                  
                }
                .ratingStatusGood {
                    background: linear-gradient(90deg, #fbad3e 0%, #fbad3e 62.5%, #fbad3e 98.44%);
                }                
                .ratingStatusFair {                    
                    background: linear-gradient(90deg, #ef893f 0%, #ef893f 62.5%, #ef893f 98.44%);
                }                
                .ratingStatusPoor {                    
                    background: linear-gradient(90deg, #da7c72 0%, #da7c72 62.5%, #da7c72 98.44%);
                }                
                .ratingStatusNotEligibile {                    
                    background: linear-gradient(90deg, #747474 0%, #747474 62.5%, #747474 98.44%);
                }
                .gradientCover {
                    border-left: 0px;
                }
               .slide1bg {                   
                    width:86%
                }
                @media (max-width: 767px) {
                    .selectInputBox{
                        margin-top:20px
                    }
                    #interestDeposits{
                      width:100%!important
                    }
                    .slid4 .selectBoxForm .form-control {
                      padding: 0 16px;
                    }
                }
                .slide1text p{
                font-size:20px
                }
                #interestDeposits{
                    width:120%
                }
                .interestDepositsarrow{
                    margin-right: -6px;
                    right: 0;    
                }
                .tableScrollUems{
                    position: relative;
                    overflow: auto;
                    height: 190px;
                }
                .tableScrollUems::-webkit-scrollbar {
                    width: 8px;
                    height: 8px;
                    background-color: ${theme.color.border};
                } 
                .tableScrollUems::-webkit-scrollbar-thumb {
                    border-radius: 8px;
                    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
                    background-color: ${theme.color.primary};
                } 
                .table td, .table th{
                    padding:0.52rem;
                    vertical-align:initial!important
                }
                .carousel-eligibility-check{
                    width:100%
                }
                tbody .ratingStatusNotEligibile{
                     font-size:10px!important
                }
                .table td, .table th:last-child{
                    width:70px
                }                
            `
        }
        return ``
    }
    return ``
}

export const GlobalStyle = createGlobalStyle`
  body {
    background: ${({theme}) => theme.color.bgColor};
    font-family: ${({theme}) => theme.font.name};
  }

  button, input, optgroup, select, textarea {
    font-family: ${({theme}) => theme.font.name} !important;
  }


  nav {
    height: 80px;
    width: 100%;
    background: ${({theme}) => theme.color.white};
    margin: 0 0 50px 0;
    -webkit-box-shadow: 0px 10px 12px -5px rgba(212, 210, 212, 1);
    -moz-box-shadow: 0px 10px 12px -5px rgba(212, 210, 212, 1);
    box-shadow: 0px 10px 12px -5px rgba(212, 210, 212, 1);
  }

  h1 {
    font-size: 22px;
    padding-left: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    color: ${({theme}) => theme.color.black_dark};
    font-weight: bold;
  }

  h2 {
    font-size: 24px;
    padding-left: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    color: ${({theme}) => theme.color.primary};
    font-weight: bold;
    text-align: left;
    padding: 0 20px;
    line-height: inherit;
  }

  h3 {
    font-size: 17px;
    padding-left: 10px;
    margin-top: 10px;
    margin: 15px 0 10px 10px;
    color: ${({theme}) => theme.color.black_light};
    font-weight: bold;
  }

  .topTitleLine {
    border-top: 1px solid ${({theme}) => theme.color.tertiary};
    width: 20%;
    margin: 0 10px;
  }

  .slide3Radio label {
    margin: 8px 0;
    color: ${({theme}) => theme.color.black_light}
  }

  .tooltip {
    background-color: ${({theme}) => theme.color.secondary} !important;
    max-width: 250px;
    min-height: 20px;
  }

  .tooltip::after {
    border-bottom-color: ${({theme}) => theme.color.secondary} !important;
  }

  .required-field {
    color: ${({theme}) => theme.color.red};
    font-weight: bold;
  }

  .tooltipMark {
    filter: ${({theme}) => theme.color.svg};
  }

  .agreement {
    font-size: 13px;
    margin: 20px;
    color: ${({theme}) => theme.color.black_light};
  }

  .meter-bad {
    position: absolute;
    right: 9px;
    top: 1px;
    font-size: 10px;
    z-index: 5;
    color: ${({theme}) => theme.color.black_dark};
  }

  .table {
    width: 100%;
    margin-bottom: 0rem;
    color: ${({theme}) => theme.color.black_light};
  }

  .tableScroll::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: ${({theme}) => theme.color.border};
  } 

  .tableScroll::-webkit-scrollbar-thumb {
    border-radius: 8px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: ${({theme}) => theme.color.primary};
  }


  .affordability-form h5 {
    margin-bottom: 2px;
    padding-bottom: 10px;
    margin-top: 0px;
    padding: 15px;
    font-size: 16px;
    color: ${({theme}) => theme.color.primary};
    border-bottom: 1px solid #d7d7d7;
  }

  .tableTileBox {
    font-size: 14px;
    font-weight: bold;
    color: ${({theme}) => theme.color.black_light};
  }

  .button-section .btn-submit {
    background: ${({theme}) => theme.color.primary2};
    padding: 9px;
    border-radius: 0px;
    color: #fff;
    margin-top: 5px;
    box-shadow: none;
    cursor: pointer;
    width: 140px;
    float: right;
  }

  .summary-scroll::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: ${({theme}) => theme.color.border};
  }

  .summary-scroll::-webkit-scrollbar-thumb {
    border-radius: 8px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: ${({theme}) => theme.color.primary};
  }

  .tableRatindStatus {
    display: inline-block;
    margin: 4px auto;
    border-radius: 1px;
    padding: 3px 10px !important;
    color: ${({theme}) => theme.color.white};
  }

  .Mui-checked .MuiSvgIcon-root {
    fill: ${({theme}) => theme.color.primary} !important;
  }

  #myModal .modal-content {
    background: ${({theme}) => theme.color.secondary2} !important;
    color: ${({theme}) => theme.color.gray} !important;
  }

  #myModal .modal-content .modal-header button {
    color: ${({theme}) => theme.color.gray} !important;
  }

  #myModal .modal-content .modal-body {
    font-size: 14px;
  }

  #myModal .modal-content .modal-body h2 {
    color: ${({theme}) => theme.color.gray} !important;
    font-size: 18px;
    margin-top: 0;
  }

  .gradientCover {
    width: 100%;
    height: 100%;
    position: absolute;
    border-left: 3px solid black;
    top: 0;
    right: 0;
    background: ${({theme}) => theme.color.white};
    overflow: hidden;
  }

  .reportUnderline {
    cursor: pointer;
    text-decoration: underline;
    color: ${({theme}) => theme.color.secondary};
  }

  .sammarySubtitle {
    color: ${({theme}) => theme.color.primary};
  }

  .summaryItem {
    padding: 0 30px;
    font-size: 14px;
    color: ${({theme}) => theme.color.black_light};
  }

  .summaryBoxWithBorder {
    border-top: 1px solid ${({theme}) => theme.color.tertiary};
    padding-top: 20px;
  }

  .NoRadioButton p {
    font-size: 12px;
    padding: 30px;
    color: ${({theme}) => theme.color.message_color};
  }

  .selectInputBox {
    font-size: 14px;
  }

  .selectInputBox div h3 {
    color: ${({theme}) => theme.color.primary};
    font-size: 15px;
  }

  #phone-form-control {
    border: 0;
    border-bottom: 1px solid ${({theme}) => theme.color.tertiary};
    width: 100%;
    border-radius: 0;
  }

  #phone-form-control:focus, #flag-dropdown:focus {
    box-shadow: none !important;
    border-bottom: 2px solid darken(${({theme}) => theme.color.tertiary}, $amount: 40);
    background: none;
  }

  #phone-form-control:active, #flag-dropdown:focus {
    box-shadow: none !important;
    border-bottom: 2px solid darken(${({theme}) => theme.color.tertiary}, $amount: 40);
    background: none;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:active,
  input:-webkit-autofill:focus {
    background-color: ${({theme}) => theme.color.white} !important;
  }

  #flag-dropdown {
    border: 0;
    border-bottom: 1px solid ${({theme}) => theme.color.tertiary};
    border-radius: 0;
    background-color: ${({theme}) => theme.color.white};
  }

  .invalid-number {
    background-color: ${({theme}) => theme.color.white} !important;
    border-color: ${({theme}) => theme.color.red} !important;
  }

  #next .spinner > div {
    width: 10px;
    height: 10px;
    background-color: ${({theme}) => theme.color.white};
    margin: 0 3px;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }

  .sammarySubtitleRight div {
    display: inline-block;
    border: 1px solid ${({theme}) => theme.color.primary};
    padding: 5px 15px 1px 15px;
    font-size: 14px;
    cursor: pointer;
  }

  .sammaryHeaderTopBorder {
    border-top: 1px solid ${({theme}) => theme.color.primary};
    padding-top: 17px;
  }

  .changeLanguageBox:hover {
    background: ${({theme}) => theme.color.gray};
    border-radius: 2px;
  }

  .menu-language div:hover {
    background: ${({theme}) => theme.color.gray};
  }

  #loan-slide {
    min-height: 32rem;
    padding: 1rem;
    margin-bottom: 25px;

    &.message {
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${({theme}) => theme.color.black_dark};
      font-weight: bold;
      font-size: 2rem;

      p {
        margin: auto;
      }
    }
  }

  .container {
    max-width: 760px;

  }

  .slick-list {
    height: 100%;

  }

  .slickScroll .slick-list {
    overflow-y: auto;
  }

  .progress-circle {
    border-radius: 100%;
    position: relative;
    overflow: hidden;
    height: 100px;
    width: 100px;
    left: 0;
    top: -6px;
    z-index: 100;
  }

  .loaderImage {
    width: 50px;
  }

  .firstTitle {
    height: 400px;
  }

  .slide1text p {
    line-height: 24px;
    font-weight: bold;
    font-size: 25px;
  }

  .vertical_center {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }


  .poweredByImage {
    width: 100px;
  }


  .eligibility-controls .eligibility-btn {
    margin-right: -4px;
    padding: 13px 30px;
    left: inherit;
    right: inherit;
    display: inline-block;
    vertical-align: bottom;
    bottom: 0;
    border: 0;
    cursor: pointer;
  }

  .eligibility-btn i {
    margin: 0 4px;
  }


  .eligibility-controls .eligibility-btn:hover i {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 500px;
  }

  .button-section .btn-submit:hover i {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 500px;
  }

  @keyframes shake {
    10%, 90% {
      transform: translate3d(-2px, 0, 0);
    }

    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
      transform: translate3d(-2px, 0, 0);
    }

    40%, 60% {
      transform: translate3d(2px, 0, 0);
    }
  }

  .slider {
    margin: 0 auto;
    width: 100%;
  }

  .slick-prev,
  .slick-next {
    display: none !important;
  }

  .user-type {
    list-style: none;
    padding: 0;
    margin: 0;
    max-width: 352px;
    width: 100%;
    margin: 10% auto;
  }

  .iconBox {
    float: left;
    width: 50%;
    padding: 15px;
    position: relative;

    &:focus {
      outline: 0;
    }
  }

  .iconBox p {
    font-size: 14px;
  }

  .slide3Radio {
    margin: 25px;
  }


  .FixedBoxWraper {
    margin-left: 10px;
  }

  .InputTextWithFixedBox {
    padding-left: 0;
  }

  .SelectTopTitle {
    font-size: 14px;
  }

  .selectInputBoxOld {
    margin: 50px 25px 30px 25px;
  }

  .selectInputBox {
    margin: 25px 25px 20px 15px;
  }


  .fieldTitle {
    margin: 15px 0;
    font-size: 15px;
  }

  .result-widget p {
    margin-bottom: 0;
    font-size: 16px;
  }

  .resultResponsive .maxText {
    font-size: 16px;
  }

  .meter-good-box {
    position: relative;
    width: 100%;
    margin-top: 2px;
    margin-bottom: 16px;
  }

  .price-disp p {
    margin-bottom: 0;
    margin-top: 0;
  }

  .disp {
    margin-top: 5px;
  }


  .table-striped tbody tr:nth-of-type(odd) {
    background-color: #fff;
  }

  .table-striped tbody tr:nth-of-type(even) {
    background-color: #ededed !important;
  }


  .tableScroll {
    position: relative;
    overflow: auto;
    height: 190px;
  }

  .tableScroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    background-color: #F5F5F5;
  }

  .slideMinH {
    min-height: 600px;
  }

  .tableScroll > table > thead > tr > th {
    white-space: nowrap;
  }

  .tableScroll > table > tbody > tr > td {
    white-space: nowrap;
  }


  .loan-eligibility h5.title-04 {
    font-size: 16px;
    margin-top: 0;
    color: #4b4b4b;
    margin-bottom: 5px;
    font-weight: bold;
  }

  .affordability-form table {
    font-size: 12px;
  }

  .table-striped > tbody > tr:nth-of-type(even) {
    background-color: #f9f9f9;
    border-bottom: 1px solid #dddddd;
  }

  .affordability-form table tr td {
    padding: 4px 8px;
  }

  .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
    border-top: 1px solid #dddddd;
  }

  .tableRow {
    width: 150px;
    text-align: center;
    font-size: 13px;
    padding: 0px 5px;
  }

  .tableScroll > .table > div > .row {
    overflow-x: auto;
    white-space: nowrap;
  }

  .tableScroll > .table > div > .row > span {
    display: inline-block;
    float: none;
  }

  .tableScroll .table div div:nth-of-type(even) {
    background-color: #f9f9f9;
    border-bottom: 1px solid #dddddd;
  }

  .tableScroll .table div div {
    padding: 10px;
  }

  .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
    border-top: 1px solid #dddddd;
  }

  .myRow {
    display: flex;
    flex-wrap: wrap;
  }

  .button-section {
    margin-top: 10px;
  }

  .button-section i {
    font-size: 14px;
    color: #585858;
  }


  .button-section .btn-submit i {
    color: #fff;
  }

  .summaryTitle {
    margin-bottom: 20px;
    margin-top: 0
  }

  .summary-scroll {
    height: 200px;
    overflow-y: auto;
    overflow-x: auto;
    margin-bottom: 15px;
    width: calc(100%);
  }

  .summary-scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    background-color: #F5F5F5;
  }


  .sammarySubtitle {
    font-size: 15px;
    margin-top: 0;
    color: #4b4b4b;
    margin-bottom: 5px;
    font-weight: bold;
  }

  .gradient small {
    height: 15px;
    display: block;
    background-size: 100%;
  }

  .ratingStatusExcellent {
    background: #96b140;
  }

  .ratingStatusGood {
    background: #fbad3e;
  }

  .ratingStatusFair {
    background: #ef893f;
  }

  .ratingStatusPoor {
    background: #da7c72;
  }

  .ratingStatusNotEligibile {
    background: #747474;
  }

  #myModal .modal-content .modal-header {
    border-bottom: 0 !important;
  }


  #myModal .modal-content .modal-body p {
    padding: 0 20px !important;
  }

  .detailTitle {

    margin: 10px 0 0 0;
  }

  .ageValidMsg {
    margin: 8px 0 0 0;
    font-size: 14px;
    line-height: 18px;
    color: ${({theme}) => theme.color.red}
  }

  .summaryBox {
    margin: 10px 20px;
    padding-bottom: 10px;
  }


  .col-xs-1 {
    width: 8.33333333%;
  }

  .col-xs-2 {
    width: 16.66666667%;
  }

  .col-xs-3 {
    width: 25%;
  }

  .col-xs-4 {
    width: 33.33333333%;
  }

  .col-xs-5 {
    width: 41.66666667%;
  }

  .col-xs-6 {
    width: 50%;
  }

  .col-xs-7 {
    width: 58.33333333%;
  }

  .col-xs-8 {
    width: 66.66666667%;
  }

  .col-xs-9 {
    width: 75%;
  }

  .col-xs-10 {
    width: 83.33333333%;
  }

  .col-xs-11 {
    width: 91.66666667%;
  }

  .col-xs-12 {
    width: 100%;
  }

  .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
    float: left;
  }

  #next .spinner {
    margin: 0 auto;
    width: 100%;
    text-align: center;
  }

  #next .spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  #next .spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }

  @-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% {
      -webkit-transform: scale(0)
    }
    40% {
      -webkit-transform: scale(1.0)
    }
  }

  @keyframes sk-bouncedelay {
    0%, 80%, 100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
    }
  }

  .sammarySubtitleLeft {
    padding: 0;
  }

  .sammarySubtitleRight {
    text-align: right;
    padding: 0;
  }


  .paddingRight {
    padding-right: 0;
  }

  .sammaryHeaderTitle {
    margin: 5px 20px 0 20px;
    padding-bottom: 0;
    min-width: 220px;
    padding-right: 5px;
  }


  .MuiTypography-body1 {
    font-family: ${({theme}) => theme.font.name} !important;
    font-size: 14px !important;
  }

  .react-tel-input .country-list {
    font-family: ${({theme}) => theme.font.name} !important;
  }

  .changeLanguageBox {
    position: absolute;
    top: 10px;
    right: 10px;
    overflow: hidden;
    z-index: 9999;
    font-size: 13px;
    font-weight: bold;
    padding: 2px 4px;
    cursor: pointer;
  }

  .changeLanguageBoxIcon {
    margin: 0px 0px 0px 2px;
    vertical-align: 2px;
    font-size: 13px;
  }

  .menu-language {
    position: absolute;
    top: 12px;
    right: 12px;
    overflow: hidden;
    z-index: 9999;
    font-size: 15px;
    padding: 15px 10px;
    background: #fff;
    -webkit-box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.16);
  }

  .menu-language div {
    cursor: pointer;
    padding: 5px;
  }

  .MuiFormControlLabel-root {
    outline: 0 !important;
  }

  @media (max-width: 767px) {

    .form {
      padding: 0px;
    }

    .changeLanguageBox {
      top: 20px;
      right: 20px;
    }

    .form .eligibility-wrap {
      max-width: 100%;
      margin: 7% auto 32%;
    }

    .form .display-widget {
      min-width: 100%;
      top: 0;
      position: relative;
      left: inherit;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-top-right-radius: 200px;
    }

    .form::before {
      display: none;
    }

    .form::after {
      display: none;
    }

    .form .display-widget h1 {
      padding-left: 65px;
      height: 53px;
      display: table-cell;
      vertical-align: middle;
      padding-right: 25px;
      font-weight: bold;
      font-size: 17px;
    }

    .progress-container {
      position: absolute;
      left: 11px;
      top: 13px;
    }

    .carousel-eligibility-check {
      max-width: 100%;
      margin: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      background-size: cover;
    }

    .display-widget .progress-circle {
      width: 60px;
      height: 60px;
    }

    .detailTitle {
      font-size: 14px;
    }

    h2 {
      font-size: 22px;
    }

    .resultResponsive {
      flex: none;
      width: 100%;
      max-width: 100%;
      margin: 5px 0;
    }

    .slide1text {
      bottom: 380px;
      right: -20px;
      padding: 80px 60px;
    }

    .slide1text p {
      font-size: 24px;
    }

  }

  @media (max-width: 640px) {

    .button-section .btn-submit {
      width: 100%;
      float: none;
    }

    .eligibility-controls #next {
      padding: 9px 12px;
    }

    .eligibility-controls #previous {
      padding: 9px 12px;
    }

    .poweredByImage {
      width: 90px;
    }

    .poweredBy {
      font-size: 14px;
    }

    .react-tel-input .country-list {
      width: 270px !important;
    }

    .country-list .search-box {
      width: 240px !important;
      margin-left: 0px !important;
    }

    .slide1text {
      bottom: 385px;
      right: -15px;
      padding: 60px 35px;
    }

    .slide1text p {
      font-size: 19px;
      line-height: 22px;
    }

    .eligibility-controls #previous {
      margin: 0 10px 0 0;
    }

  }


  @media (max-width: 576px) {
    .selectInputBox {
      margin: 0px 15px 5px 15px;
    }

    .SelectTopTitle {
      margin: 15px 0 0 0;
    }
  }

  @media (max-width: 520px) {
    .react-tel-input .country-list {
      width: 250px !important;
    }

    .country-list .search-box {
      width: 230px !important;
    }
  }


  @media (max-width: 480px) {

    h2 {
      font-size: 19px;
    }


    .slide1text {
      padding: 90px 15px;
    }

    .slide1text p {
      font-size: 19px;
      line-height: 20px;
    }

    .slid4 .selectBoxForm .form-control {
      padding: 0 3px;
    }

    .react-tel-input .country-list {
      width: 210px !important;
    }

    .country-list .search-box {
      width: 190px !important;
    }

    #loan-slide {
      padding: 1rem 0;
    }

    .summary-wrap {
      margin-right: 0;
    }

    .summaryBox .sammarySubtitleLeft {
      width: 100%;
    }

    .summaryBox .sammarySubtitleRight {
      width: 100%;
      text-align: left;
    }

    .summaryBox .summaryItem .col-xs-6 {
      width: 100%;
    }

    .summaryBox .summaryItem {
      margin-bottom: 10px;
      padding: 0 6px;
    }

    .summaryBox .summaryItem div:first-child {
      font-weight: bold;
    }

  }

  @media (max-width: 450px) {
    .slide1text p {
      display: contents;
    }
  }

  @media (max-width: 420px) {
    .eligibility-controls #next {
      padding: 7px 10px;
      font-size: 12px;
    }

    .eligibility-controls #previous {
      padding: 7px 10px;
      font-size: 12px;
    }

    .poweredByImage {
      width: 60px;
    }

    .slide1bg {
      width: 98%;
    }

    .slide1text {
      bottom: 400px;
      padding: 90px 10px;
      right: 0px;
      width: 98%;
      padding: 60px 35px;
    }

    .slide1text p {
      font-size: 16px;
      line-height: 19px;
      display: contents;
    }

    .poweredBy {
      font-size: 10px;
    }

    .iconBox p {
      font-size: 13px;
    }

    .iconBox {
      padding: 5px;
    }

    .MuiTypography-body1 {
      font-size: 14px !important;
    }

    .selectInputBoxOld {
      margin: 40px 5px 10px 10px;
    }

    .selectInputBox {
      margin: 0px 0px 0px 0px;
    }

    h3 {
      font-size: 16px;
    }

    .detailTitle {
      font-size: 14px;
      padding: 0;
    }

    .react-tel-input .country-list {
      width: 140px !important;
      font-size: 12px;
    }

    .country-list .search-box {
      width: 120px !important;
      margin-left: 0px !important;
    }

    .summaryTitle {
      margin-bottom: 10px;
    }

    .summaryBox {
      margin: 5px 20px;
      padding-bottom: 5px;
    }

    .summaryItem {
      padding: 0 6px;
      font-size: 14px;
    }

    .NoRadioButton p {
      padding: 15px;
    }

    .result-widget {
      min-height: 100px;
    }

    .rate {
      font-size: 35px;
    }

    .result-widget p {
      font-size: 13px;
    }

    .price-disp {
      min-height: 100px;
    }

    .disp {
      margin-top: 5px;
    }

    .price-disp small {
      font-size: 35px;
    }

    .affordability-form h5 {
      font-size: 15px;
    }

    .rateTooltip {
      display: block !important;
      margin: 8px 0 !important;
      font-size: 12px !important;
    }

    .rateTooltipLeft {
      padding: 5px 5px !important;
      width: 100% !important;
    }

    .rateTooltipRight {
      padding: 10px 20px !important;
      width: 250px !important;
      text-align: center !important;
    }


  }


  #loan-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.5rem;
  }


  @media (max-width: 340px) {
    .slid4 .selectBoxForm .form-control {
      padding: 0;
      font-size: 11px;
    }

    .FixedBoxWraper {
      margin-left: 0;
    }

    h2 {
      padding-left: 0;
      padding: 0;
    }

    h3 {
      padding-left: 0;
      margin: 15px 0 10px 0px;
    }

  }

  @media (max-width: 320px) {

  }

  ${({theme}) => extendElementsForTheme(theme)}

`